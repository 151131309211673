import { createPortal } from 'react-dom';
import styles from './ImageViewer.module.scss';
import { useEffect, useState } from 'react';

export const ImageViewer = ({ url, className = '', style = {}, children }) => {
  const [showImage, setShowImage] = useState(false);

  const openImage = (ev) => {
    if (!url) return;
    ev.stopPropagation();
    setShowImage(true);
  };

  const backing = (
    <div className={styles.backing}></div>
  );

  const container = (
    <div className={styles.container}>
      <img src={url} />
    </div>
  );

  const hideImage = () => {
    setShowImage(false);
  };

  useEffect(() => {
    document.addEventListener('click', hideImage);
    return () => {
      document.removeEventListener('click', hideImage);
    };
  }, []);

  return (
    <>
      <div
        data-active={url ? 'active' : ''}
        className={className + ' ' + styles.viewer}
        style={style}
        onClick={openImage}>
        {children}
      </div>
      {url && showImage && createPortal((
        <>
          {backing}
          {container}
        </>
      ), document.getElementById('overlay'))}
    </>
  );
};
