import { parseRrule } from 'rruler';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './EventModal.module.scss';
import { Close, Delete } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { calendarSlice, fetchEvents } from '../../store/calendar';
import { EventTabs } from './EventTabs/EventTabs';
import { EventParticipants } from './EventParticipants/EventParticipants';
import { EventPayment } from './EventPayment/EventPayment';
import { EventMetrics } from './EventMetrics/EventMetrics';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import { api } from '@lib/api';
import { EventForm } from '../EventForm/EventForm';
import moment from 'moment';
import { startProgress } from '../../../../store/common';
import { RemoveConfirmation } from '../../../../components/ui/RemoveConfirmation/RemoveConfirmation';
import { AppButton } from '../../../../components/ui/AppButton/AppButton';

export const EventModal = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(window.location?.hash?.slice(1) || 'info');
  const pageTitle = usePageTitle();
  const formRef = useRef();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const modalClosed = () => {
    navigate('/calendar/');
    dispatch(calendarSlice.actions.setEvent(null));
  };

  const cancelButtonClicked = () => {
    modalClosed();
  };

  const saveButtonClicked = () => {
    formRef.current.submit();
  };

  const proceedDeletion = async () => {
    dispatch(startProgress());
    await api.delete(`/calendar/events/${event.id}`);
    navigate('/calendar');
    dispatch(fetchEvents());
  };

  const deleteEvent = () => {
    setShowDeleteConfirmation(true);
  };

  const updateEvent = async (data) => {
    dispatch(startProgress());
    delete data.repeat;
    delete data.avatar;
    await api.patch(`/calendar/events/${event.id}`, data);
    const { id } = data;
    await uploadAvatar(id, window._eventAvatarFile);
    delete window._eventAvatarFile;
    dispatch(fetchEvents());
    modalClosed();
  };

  const uploadAvatar = async (id, file) => {
    if (!file) return;
    if (file === 'delete') {
      return await api.delete(`calendar/events/${id}/avatars`);
    }
    return new Promise((resolve) => {
      const blobReader = new FileReader();
      blobReader.onload = async (e) => {
        const blob = new Blob([new Uint8Array(e.target.result)], {
          type: file.type,
        });
        const formData = new FormData();
        formData.append('avatar', blob, 'avatar.jpg');
        await api.post(`calendar/events/${id}/avatars`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        resolve();
      };
      blobReader.readAsArrayBuffer(file);
    });
  };

  const parseRepeat = (val) => {
    try {
      const rrule = parseRrule(val);
      if (rrule.frequency === 'DAILY') {
        return 'day';
      }
      if (rrule.frequency === 'WEEKLY') {
        return 'week';
      }
      if (rrule.frequency === 'MONTHLY') {
        return 'month';
      }
      if (rrule.frequency === 'YEARLY') {
        return 'year';
      }
    } catch (err) {
      return 'no';
    }
  };

  const fetchEvent = async () => {
    dispatch(startProgress());
    const response = await api.get(`/calendar/events/${params.eventId}/full`);
    const data = {
      ...response.data,
      start: response.data.start
        ? moment(response.data.start).format('DD.MM.YYYY HH:mm')
        : null,
      finish: response.data.finish
        ? moment(response.data.finish).format('DD.MM.YYYY HH:mm')
        : null,
      repeat: parseRepeat(response.data.rrule),
    };
    setEvent(data);
  };

  useEffect(() => {
    pageTitle(event?.title);
  }, [event]);

  useEffect(() => {
    window.location.hash = tab;
  }, [tab]);

  useEffect(() => {
    fetchEvent();
  }, []);

  if (!event) return null;

  return (
    <>
      <Dialog open={true} onClose={modalClosed} className="wide-dialog">
        <DialogTitle>
          Информация о событии
          <IconButton
            aria-label="close"
            onClick={modalClosed}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close color="disabled" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: '1052px' }} className="paddingless">
          <div className={styles.modal}>
            <div className={styles.tabs}>
              <EventTabs tab={tab} onChange={setTab} />
            </div>
            <div className={styles.content} style={{ width: '100%' }}>
              {tab === 'info' && (
                <EventForm
                  initValues={event}
                  ref={formRef}
                  onSubmit={updateEvent}
                />
              )}
              {tab === 'participants' && <EventParticipants event={event} />}
              {tab === 'payment' && <EventPayment />}
              {tab === 'metrics' && <EventMetrics />}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={styles.footer}>
          <div className={styles.remove}>
            <AppButton
              color="error"
              variant="contained"
              size="small"
              onClick={deleteEvent}
            >
              Удалить событие
            </AppButton>
          </div>
          <div className={styles.cancel}>
            <Button
              color="info"
              variant="contained"
              onClick={cancelButtonClicked}
            >
              Отмена
            </Button>
          </div>
          <div className={styles.save}>
            <Button
              color="primary"
              variant="contained"
              onClick={saveButtonClicked}
            >
              Сохранить изменения
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {showDeleteConfirmation && (
        <RemoveConfirmation
          title="Удалить событие?"
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={proceedDeletion}
        >
          Вы действительно хотите удалить событие «{event.title}»?
          <br />
          Это действие нельзя будет отменить.
        </RemoveConfirmation>
      )}
    </>
  );
};
