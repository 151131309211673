import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { InviteModal } from '../ManagementModal/InviteModal/InviteModal';
import { api } from '@lib/api';
import { useDispatch } from 'react-redux';
import { fetchManagement } from '../../store/club';
import { SuccessModal } from '@ui/SuccessModal/SuccessModal';
import { usePageTitle } from '../../../../hooks/usePageTitle';

export const InviteNewModal = () => {
  const { cardId } = useParams();
  const [item, setItem] = useState();
  const [showSuccessInvite, setShowSuccessInvite] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pageTitle = usePageTitle();

  pageTitle('Приглашение пользователя');

  const fetchCard = useCallback(async () => {
    const response = await api.get(`/dashboard/club/cards/${cardId}`);
    setItem(response.data);
  }, [cardId]);

  const invite = async () => {
    const cardsIds = [+cardId];
    await api.post(`/dashboard/club/invite/`, {
      cardsIds,
    });
    dispatch(fetchManagement());
    setShowSuccessInvite(true);
  };

  useEffect(() => {
    fetchCard();
  }, [fetchCard]);

  if (!item) return null;

  return (
    <>
      <InviteModal
        items={[
          {
            id: item.cardId,
            name: `${item.common.about.firstName} ${item.common.about.lastName}`,
            email: item.common.contacts.emails[0],
          },
        ]}
        onCancel={() => navigate(`/club/`)}
        onSend={invite}
      />
      {showSuccessInvite && (
        <SuccessModal onClose={() => navigate(`/club/`)} />
      )}
    </>
  );
};
