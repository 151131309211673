import { Telegram } from '@mui/icons-material';
import { MemberCrownIcon } from '../../../../../icons/MemberCrownIcon';
import { ResidentAvatar } from '../../../../club/components/ResidentAvatar/ResidentAvatar';
import styles from './Member.module.scss';
import { IconButton } from '@mui/material';
import { EditMemberIcon } from '../../../../../icons/EditMemberIcon';
import { DeleteMemberIcon } from '../../../../../icons/DeleteMemberIcon';
import { useSelector } from 'react-redux';

export const Member = ({
  name,
  avatar,
  isManager,
  description,
  telegram,
  showTelegram,
  onEdit,
  onDelete,
  editable
}) => {
  const bgColor = useSelector(state => state.communityPage.data.bgColor);
  return (
    <div className={styles.member}>
      <div className={styles.avatar}>
        <ResidentAvatar avatar={avatar} />
      </div>
      <div className={styles.details}>
        <div className={styles.header}>
          <div className={styles.name}>{name}</div>
          <div className={styles.icon}><MemberCrownIcon color={bgColor} /></div>
        </div>
        {description && (
          <div className={styles.description}>{description}</div>
        )}
        {telegram && showTelegram && (
          <div className={styles.telegram}>
            <Telegram />
            {telegram}
          </div>
        )}
      </div>
      {editable && (
        <div className={styles.actions}>
          <IconButton onClick={onEdit}>
            <EditMemberIcon />
          </IconButton>
          <IconButton onClick={onDelete}>
            <DeleteMemberIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};
