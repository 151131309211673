export const CommunityPageIcon = () => {
  return <svg width="187" height="124" viewBox="0 0 187 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="187" height="124" rx="4" fill="#F7FAFB" />
    <path d="M34.4823 43.1219C34.4823 40.1162 36.8708 37.6796 39.8172 37.6796H147.183C150.129 37.6796 152.518 40.1162 152.518 43.1219V80.8781C152.518 83.8838 150.129 86.3204 147.183 86.3204H39.8172C36.8708 86.3204 34.4823 83.8838 34.4823 80.8781V43.1219Z" fill="#CFEAF9" />
    <path d="M75.8281 55.7073C75.8281 54.9559 76.4252 54.3467 77.1618 54.3467H122.175C122.912 54.3467 123.509 54.9559 123.509 55.7073V58.4285C123.509 59.1799 122.912 59.7891 122.175 59.7891H77.1618C76.4252 59.7891 75.8281 59.1799 75.8281 58.4285V55.7073Z" fill="white" />
    <path d="M75.8281 63.1905C75.8281 62.8148 76.1266 62.5102 76.4949 62.5102H108.505C108.873 62.5102 109.171 62.8148 109.171 63.1905V64.5511C109.171 64.9268 108.873 65.2314 108.505 65.2314H76.4949C76.1266 65.2314 75.8281 64.9268 75.8281 64.5511V63.1905Z" fill="white" />
    <path d="M75.8281 67.9526C75.8281 67.5768 76.1266 67.2723 76.4949 67.2723H108.505C108.873 67.2723 109.171 67.5768 109.171 67.9526V69.3131C109.171 69.6889 108.873 69.9934 108.505 69.9934H76.4949C76.1266 69.9934 75.8281 69.6889 75.8281 69.3131V67.9526Z" fill="white" />
    <path d="M69.1594 62.1701C69.1594 69.6844 63.1881 75.7759 55.822 75.7759C48.456 75.7759 42.4847 69.6844 42.4847 62.1701C42.4847 54.6558 48.456 48.5642 55.822 48.5642C63.1881 48.5642 69.1594 54.6558 69.1594 62.1701Z" fill="white" />
    <path d="M48.7049 73.8222C47.4678 73.0593 46.9562 71.5009 47.6222 70.209C48.051 69.3772 48.6071 68.6086 49.2777 67.9338C50.9973 66.2035 53.3296 65.2314 55.7615 65.2314C58.1934 65.2314 60.5257 66.2035 62.2453 67.9338C62.8542 68.5465 63.3686 69.2365 63.7791 69.9808C64.4812 71.254 64.0126 72.8265 62.7967 73.6238C60.7195 74.9858 58.2476 75.7759 55.5948 75.7759C53.0736 75.7759 50.7158 75.0623 48.7049 73.8222Z" fill="#2499EF" />
    <path d="M55.7615 65.2314C54.3718 65.2314 53.0391 64.6759 52.0565 63.6872C51.0739 62.6984 50.5218 61.3574 50.5218 59.9591C50.5218 58.5608 51.0739 57.2198 52.0565 56.2311C53.0391 55.2423 54.3718 54.6869 55.7615 54.6869C57.1511 54.6869 58.4839 55.2423 59.4665 56.2311C60.4491 57.2198 61.0012 58.5608 61.0012 59.9591C61.0012 61.3574 60.4491 62.6984 59.4665 63.6872C58.4839 64.6759 57.1511 65.2314 55.7615 65.2314Z" fill="#2499EF" />
  </svg>
};
