import { useSelector } from 'react-redux';
import styles from './Logo.module.scss';
import { COMMUNITY_LOGO_URLS } from '@data/constants';
import { Link } from 'react-router-dom';

export const Logo = () => {
  const community = useSelector((state) => state.community.community);
  const isDefault = !COMMUNITY_LOGO_URLS[community?.title];

  const logoUrl =
    COMMUNITY_LOGO_URLS[community?.title] || COMMUNITY_LOGO_URLS.default;
  
  // const defaultLogo = (
  //   <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1jb62vn-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.0003 15.4286C18.0003 11.8601 17.9218 4.88047 17.9218 2.65015C17.9218 2.57143 17.9218 2.46647 17.9218 2.38775V2.36152C17.9218 1.04956 16.8228 0 15.4884 0C15.0174 0 14.5726 0.131195 14.2063 0.341108H14.1801C13.1858 0.83965 12.1915 2.07289 11.0664 3.14869C9.54879 4.59184 7.24622 7.1895 5.3623 9.20991C7.45555 11.3353 9.91511 13.8805 12.113 16.2157C12.5317 16.5831 12.7933 16.7668 13.2381 17.0816C13.7614 17.4752 14.2324 17.8163 14.9651 17.9475C15.1221 17.9738 15.3052 18 15.4884 18C15.5145 18 15.5407 18 15.5407 18H15.5669C15.593 18 15.593 18 15.6192 18C16.9275 17.9213 17.9741 16.8717 17.9741 15.586C18.0003 15.5335 18.0003 15.4286 18.0003 15.4286Z" fill="url(#paint0_linear_1233_5647)"></path><path d="M3.48002 7.29439C3.1137 6.90081 2.59039 6.58594 1.96242 6.58594C0.889629 6.58594 0 7.42559 0 8.47515C0 8.50139 0 8.52763 0 8.55387V8.58011C0 9.60343 0 14.7463 0 15.9008C0 15.927 0 15.9533 0 15.9795C0 16.0058 0 16.0058 0 16.032C0 17.0816 0.863464 17.9212 1.96242 17.9212C2.48573 17.9212 2.95671 17.7113 3.32303 17.3964C3.63701 17.1603 4.26499 16.3469 4.39581 16.2157C5.28544 15.376 6.35823 14.5626 8.13749 14.5626C9.68126 14.5626 11.0942 15.2973 12.1147 16.2157C9.02712 12.9096 3.68934 7.5043 3.48002 7.29439Z" fill="#21253E"></path><defs><linearGradient class="linearGradient" id="paint0_linear_1233_5647" x1="21.1992" y1="5.67824" x2="5.29453" y2="14.8351" gradientUnits="userSpaceOnUse"><stop class="logoStop" offset="1.38522e-7" stop-color="currentColor"></stop><stop class="logoStop" offset="0.9" stop-color="currentColor" stop-opacity="0.48"></stop></linearGradient></defs></svg></svg>
  // );
  
  let logoElem = (
    <img src={logoUrl} alt="Community logo" />
  );

  // if (!COMMUNITY_LOGO_URLS[community?.title]) {
  //   logoElem = defaultLogo;
  // }

  return (
    <Link to="/" className={styles.logo} data-default={isDefault ? 'default' : ''}>
      {logoElem}
    </Link>
  );
};
