import moment from "moment";
import { validateWebsite } from "../../../lib/validation";

export const validateEventForm = ({
  city,
  conditions,
  description,
  finish,
  format,
  link,
  restricted,
  repeat,
  start,
  title,
  type,
  price,
}) => {
  const errors = {};
  const startDate = start ? moment(start, 'DD.MM.YYYY HH:mm').toDate() : null;
  const finishDate = finish ? moment(finish, 'DD.MM.YYYY HH:mm').toDate() : null;

  if (city && (city.length < 2 || city.length > 30)) {
    errors.city = 'Укажите корректный город';
  }
  if (description) {
    if (description.length < 15 || description.length > 2000) {
      errors.description = 'От 15 до 2000 символов'
    }
  } else {
    errors.description = 'Введите описание события';
  }
  if (startDate) {
    if (!(startDate instanceof Date) || isNaN(startDate.getTime())) {
      errors.start = 'Укажите корректную дату';
    }
  }
  if (finishDate) {
    if (!startDate || isNaN(startDate.getTime())) {
      errors.start = 'Укажите дату начала';
    } else if (!(finishDate instanceof Date) || isNaN(finishDate.getTime())) {
      errors.finish = 'Укажите корректную дату';
    }
    //  else if (finishDate.getTime() < startDate.getTime()) {
    //   errors.finish = 'Дата окончания должна быть позже даты начала';
    // }
  }
  if (link && !validateWebsite(link)) {
    errors.link = 'Введена некорректная ссылка';
  }
  if (!title) {
    errors.title = 'Введите название';
  } else if (title.length < 2 || title.length > 80) {
    errors.title = 'От 2 до 80 символов';
  }
  if (type) {
    if (type.length < 2 || type.length > 80) {
      errors.type = 'От 2 до 80 символов';
    } else if (!RegExp(/^[А-ЯЁа-яёA-z\d\s\-()'"]{2,80}$/).test(type)) {
      errors.type = 'Введен некорректный тип';
    }
  }
  if (conditions === 'paid') {
    if (!price) {
      errors.price = 'Введите стоимость участия';
    } else if (price < 1 || price > 1000000000000) {
      errors.price = 'От 1 до 1 000 000 000 000';
    }
  }
  return errors;
};
