import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

export default moment;

const mnt = moment;

export { mnt };
