import { ClubRoot } from '../../features/club/components/ClubRoot/ClubRoot';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Page } from '../../layouts/Page/Page';

import { Outlet } from 'react-router-dom';

export const ClubPage = () => {
  const pageTitle = usePageTitle();

  pageTitle('Управление сообществом');

  return (
    <Page>
      <ClubRoot />
      <Outlet />
    </Page>
  );
};
