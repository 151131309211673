import { SupportAgent, Telegram } from '@mui/icons-material';
import { Page } from '../../layouts/Page/Page';
import { Heading } from '../../layouts/Heading/Heading';

import styles from './Support.module.scss';
import { usePageTitle } from '../../hooks/usePageTitle';

export const SupportPage = () => {
  const pageTitle = usePageTitle();

  pageTitle('Поддержка');

  return (
    <Page
      breadcrumbs={[
        {
          title: 'Поддержка',
          icon: SupportAgent,
          url: '/support/',
        },
      ]}
    >
      <div className={styles.support}>
        <div className={styles.heading}>
          <Heading icon={SupportAgent} title="Поддержка"></Heading>
        </div>
        <div className={styles.header}>Контакты</div>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>Вопросы и предложения:</div>

          <div className={styles.sectionContent}>
            <p>
              <a
                href="mailto:support@ogon.team"
                target="_blank"
                className={styles.email} rel="noreferrer"
              >
                support@ogon.team
              </a>
            </p>
            <br />
            <div>
              <a
                href="https://t.me/OgonSupportBot"
                target="_blank"
                className={styles.telegram} rel="noreferrer"
              >
                <div className={styles.icon}>
                  <Telegram />
                </div>
                <div className={styles.title}>Лидерпорт Поддержка</div>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>Партнерство:</div>
          <div className={styles.sectionContent}>
            <p>
              <a
                href="mailto:av@ogon.team"
                target="_blank"
                className="email" rel="noreferrer"
              >
                av@ogon.team
              </a>
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
};
