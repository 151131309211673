import { useEffect, useState } from 'react';
import styles from './ManagementTable.module.scss';
import { CheckBox } from '@ui/CheckBox/CheckBox';
import { Link } from 'react-router-dom';
import { Link as LinkIcon } from '@mui/icons-material';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { AppButton } from '../../../../../components/ui/AppButton/AppButton';
import { CopiedSnack } from '../../LinkInvite/CopiedSnack/CopiedSnack';
import { copyToClipboard } from '../../../../../lib/clipboard';
import { useGetCommunity } from '../../../hooks/useGetCommunity';
import { api } from '@lib/api';

export const ManagementTable = ({
  tab,
  residents: cards,
  onCheck,
  showPaymentLink,
}) => {
  const { community } = useSelector((state) => state.community);
  const [checked, setChecked] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const getCommunity = useGetCommunity();

  const toggleChecked = (resident) => {
    setChecked((prev) => {
      const items = [...prev];
      const index = items.indexOf(resident.id);
      if (index > -1) {
        items.splice(index, 1);
      } else {
        items.push(resident.id);
      }
      return items;
    });
  };

  const getCards = () => {
    const items = sortBy(cards, 'id').reverse();
    return items;
  };

  const copyPaymentLink = async (card) => {
    const { data: link } = await api.post(`/payments/link`, {
      communityId: card.communityId,
      userId: card.userId,
    });
    copyToClipboard(link);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 3000);
  };

  useEffect(() => {
    onCheck(checked);
  }, [checked]);

  useEffect(() => {
    if (checkAll) {
      setChecked([...cards]);
    } else {
      setChecked([]);
    }
  }, [checkAll]);

  return (
    <>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <th>
                <CheckBox
                  checked={checkAll}
                  onChange={() => setCheckAll((prev) => !prev)}
                />
              </th>
              <th>Имя Фамилия</th>
              <th>Сообщество</th>
              <th>Почта</th>
              <th>Телеграм</th>
              {community.isPaid && showPaymentLink && <th></th>}
            </tr>
          </thead>
          <tbody>
            {getCards().map((card) => (
              <tr key={card.id}>
                <td>
                  <CheckBox
                    checked={checked.includes(card.id)}
                    onChange={() => toggleChecked(card)}
                  />
                </td>
                <td>
                  <Link
                    to={`/club/${card.id}/?redirectTo=${encodeURIComponent(
                      `/club/management/#${tab}`
                    )}`}
                  >
                    {card.firstName} {card.lastName}
                  </Link>
                </td>
                <td>{getCommunity(card.communityId)?.title}</td>
                <td>{card.email}</td>
                <td>{card.telegram || 'Не указано'}</td>
                {community.isPaid && showPaymentLink && (
                  <td>
                    <AppButton
                      size="small"
                      startIcon={<LinkIcon />}
                      color="secondary"
                      onClick={() => copyPaymentLink(card)}
                    >
                      Ссылка на оплату
                    </AppButton>
                  </td>
                )}
              </tr>
            ))}
            {!cards?.length && (
              <tr>
                <td colSpan={5}>Ни одного резидента не найдено.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showCopied && <CopiedSnack />}
    </>
  );
};
