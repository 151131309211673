import { useEffect, useState, useRef } from 'react';
import styles from './ColorPicker.module.scss';
import { HexColorPicker } from "react-colorful";


export const ColorPicker = ({ x, y, onClose, value, onChange }) => {
  const [pos, setPos] = useState([x, y]);
  const [initDone, setInitDone] = useState(false);
  const [hex, setHex] = useState(value);
  const pickerRef = useRef(null);
  const hexInputRef = useRef(null);

  const handleDocumentClick = () => {
    onClose && onClose();
  };

  const updatePosition = () => {
    const elem = pickerRef.current;
    const bottom = elem.getBoundingClientRect().height + y;
    const winBottom = window.scrollY + window.innerHeight;

    const right = elem.getBoundingClientRect().width + x;
    const winRight = window.scrollX + window.innerWidth;

    setPos(prev => [
      (right + 30) > winRight
      ? prev[0] - (right - winRight) - 50
      : prev[0],
      (bottom + 30) > winBottom
      ? prev[1] - (bottom - winBottom) - 30
      : prev[1]
    ]);

    setInitDone(true);
  };

  const selectHex = () => {
    hexInputRef.current.select();
  };

  const updateHex = () => {
    if (!/^#[0-9A-F]{6}$/i.test(hex)) {
      return;
    }
    onChange(hex);
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);
    return () => {
      document.addEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      updatePosition();
    }, 0);
  }, [x, y]);

  useEffect(() => {
    updateHex();
  }, [hex]);

  return (
    <div
    data-init={initDone}
    ref={pickerRef}
    className={styles.picker}
    onClick={ev => ev.stopPropagation()}
    style={{ top: `${pos[1]}px`, left: `${pos[0]}px` }}>
      <div className={styles.native}>
      <HexColorPicker color={hex} onChange={val => {
        setHex(val);
        onChange(val);
      }} />
      </div>
      <div className={styles.hex}>
        <label>Hex:</label>
        <input ref={hexInputRef} type="text" value={hex} onChange={ev => setHex(ev.target.value)} onClick={selectHex} />
      </div>
    </div>
  )
};
