import { useEffect, useRef, useState } from 'react';
import { UploadPhotoIcon } from '../../../../icons/UploadPhotoIcon';
import styles from './EventAvatar.module.scss';
import { Delete } from '@mui/icons-material';

export const EventAvatar = ({ url, onChange }) => {
  const inputRef = useRef();
  const [error, setError] = useState(null);
  const [dataUrl, setDataUrl] = useState(() => url || null);
  const [file, setFile] = useState(null);

  const updateDataUrl = () => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setDataUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const selectFileOrDelete = () => {
    if (file || dataUrl) {
      setDataUrl(null);
      setFile(null);
      onChange(null);
    } else {
      inputRef.current.click();
    }
  };

  const fileSelected = (ev) => {
    const file = ev.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
      setError('Максимальный размер изображения — 10 MB.');
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function (e) {
      const image = new Image();

      image.src = e.target.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (width < 500 || height < 500) {
          setError('Минимальный размер изображения — 500 на 500 пикселей.');
        } else {
          setError(null);
          setFile(file);
          onChange(file);
        }
        return true;
      };
    };
  };

  useEffect(() => {
    if (!file) return;
    updateDataUrl();
  }, [file]);

  return (
    <div className={styles.avatar}>
      {!file && (
        <input
          type="file"
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={fileSelected}
          accept=".png,.jpg,.jpeg,.webp"
        />
      )}
      <div className={styles.droparea} onClick={selectFileOrDelete}>
        {!dataUrl && (
          <>
            <div className={styles.icon}>
              <UploadPhotoIcon />
            </div>
            <div className={styles.label}>Загрузить обложку</div>
          </>
        )}
        {dataUrl && (
          <div
            className={styles.image}
            style={{ backgroundImage: `url("${dataUrl}")` }}
          >
            <div className={styles.delete}>
              <div className={styles.icon}>
                <Delete />
              </div>
              <div className={styles.label}>Удалить</div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.description}>
        <div className={styles.line}>
          Вы можете загрузить изображение в формате PNG, JPG, JPEG или WEBP.
        </div>
        <div className={styles.line}>
          Минимальный размер изображения 500х500px.
        </div>
        {error && (
          <div className={styles.line + ' ' + styles.error}>{error}</div>
        )}
      </div>
    </div>
  );
};
