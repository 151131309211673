import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './InviteModal.module.scss';
import { Close } from '@mui/icons-material';
import { AppButton } from '@ui/AppButton/AppButton';
import { Field } from '@ui/Field/Field';
import { useSelector } from 'react-redux';
import { usePageTitle } from '../../../../../hooks/usePageTitle';

export const InviteModal = ({ items, onCancel, onSend, isName }) => {
  const { firstName, lastName } = useSelector((state) => state.auth.profile);
  const { community } = useSelector((state) => state.community);
  const type =
    !isName &&
      items.map((item) => item.email).filter((val) => !!val).length ===
      items.length
      ? 'email'
      : 'name';
  const pageTitle = usePageTitle();

  pageTitle('Приглашение пользователя');

  const getLabel = () => {
    if (type === 'email') {
      return 'на почту';
    }
    return 'пользователям';
  };

  const getName = (item) => {
    if (type === 'name') {
      return item.name;
    }
    return item.email || item.name;
  };

  return (
    <Dialog open={true} onClose={onCancel} className="wide-dialog">
      <DialogTitle>
        Пригласить на платформу
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '592px', overflowX: 'hidden' }}>
        <div className={styles.invite}>
          <Field label={`Мы отправим приглашение ${getLabel()}:`}>
            <div className={styles.names}>
              {items.map((item) => getName(item)).join(', ')}
            </div>
          </Field>
          <div className={styles.example}>
            <div className={styles.header}>
              Приглашение от сообщества «{community.title}»
            </div>
            <div className={styles.description}>
              <p>
                {firstName} {lastName} приглашает вас присоединиться к цифровому
                пространству сообщества «{community.title}».
              </p>
              <p>
                Для регистрации пройдите{' '}
                <span className={styles.link}>по ссылке</span>.
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <AppButton color="secondary" onClick={onCancel}>
          Отмена
        </AppButton>
        <AppButton color="primary" onClick={onSend}>
          Отправить
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};
