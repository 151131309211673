import { useEffect, useState } from 'react';
import styles from './CommunityLinks.module.scss';
import { api } from '@lib/api';
import { AppButton } from '../../../../components/ui/AppButton/AppButton';
import { Add } from '@mui/icons-material';
import { CommunityLinkModal } from '../CommunityLinkModal/CommunityLinkModal';
import { CommunityLink } from '../CommunityLink/CommunityLink';
import { useDispatch, useSelector } from 'react-redux';
import { startProgress } from '../../../../store/common';
import { sortBy } from 'lodash';
import { FormControlLabel, Switch } from '@mui/material';
import { updateUnsavedData } from '../../../community-page/store/community-page.slice';
import { createPortal } from 'react-dom';

export const CommunityLinks = ({ communityId }) => {
  const [links, setLinks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [link, setLink] = useState(null);
  const { community } = useSelector((state) => state.community);
  const { unsavedData: { showLinksOnlyForMembers } } = useSelector((state) => state.communityPage);
  const [pos, setPos] = useState([200, 200]);

  const dispatch = useDispatch();

  const fetchLinks = async () => {
    const { data } = await api.get(`/communities/links/all/`);
    setLinks(sortBy(data, 'id'));
  };

  const createLink = async (data) => {
    const { data: link } = await api.post(`/communities/links/`, {
      title: data.title,
      url: data.url,
    });
    await uploadAvatar(link.id, data.avatar);
  };

  const uploadAvatar = async (id, file) => {
    if (!file) return;
    return new Promise((resolve) => {
      const blobReader = new FileReader();
      blobReader.onload = async (e) => {
        const blob = new Blob([new Uint8Array(e.target.result)], {
          type: file.type,
        });
        const formData = new FormData();
        formData.append('avatar', blob, 'avatar.jpg');
        await api.post(`/communities/links/${id}/avatars`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        resolve();
      };
      blobReader.readAsArrayBuffer(file);
    });
  };

  const saveLink = async (data) => {
    await api.patch(`/communities/links/${link.id}`, {
      title: data.title,
      url: data.url
    });
    if (!(data.avatar instanceof File)) {
      return;
    }
    await uploadAvatar(link.id, data.avatar);
  };

  const editLink = (link) => {
    setLink(link);
    setShowModal(true);
  };

  const deleteLink = async (link) => {
    dispatch(startProgress());
    await api.delete(`/communities/links/${link.id}`);
    fetchLinks();
  };

  const linkSaved = async (data) => {
    dispatch(startProgress());
    if (!link) {
      await createLink(data);
    } else {
      await saveLink(data);
    }
    setShowModal(false);
    setLink(null);
    fetchLinks();
  };

  const toggleOnlyMembers = async () => {
    dispatch(updateUnsavedData({ showLinksOnlyForMembers: !showLinksOnlyForMembers }));
  };

  const addMoreClicked = (ev) => {
    setPos([ev.pageX, ev.pageY]);
    setShowModal(true);
  };

  useEffect(() => {
    fetchLinks();
  }, []);

  return (
    <div className={styles.links}>
      <div className={styles.description}>
        Добавленные здесь ссылки будут закреплены на главной странице вашего сообщества. Вы можете добавить любые ссылки, например, сайт партнёров, рекламу или Google-форму.
      </div>
      {links.length > 0 && (
        <div className={styles.visibility}>
          <FormControlLabel control={<Switch checked={showLinksOnlyForMembers} />} onClick={toggleOnlyMembers} />
          <div className={styles.label} onClick={toggleOnlyMembers}>Виден только участникам сообщества</div>
        </div>
      )}
      {links.length > 0 && <div className={styles.list}>
        {links.map((link) => (
          <CommunityLink key={link.id} link={link} onEdit={() => editLink(link)} onDelete={() => deleteLink(link)} />
        ))}
      </div>}
      <div className={styles.add}>
        <AppButton
          startIcon={<Add />}
          color="secondary"
          onClick={addMoreClicked}>
          Добавить
        </AppButton>
      </div>
      {
        showModal && createPortal(<CommunityLinkModal
          link={link}
          pos={pos}
          onClose={() => {
            setShowModal(false);
            setLink(null);
          }}
          onSave={linkSaved} />, document.getElementById('overlay'))
      }
    </div>
  );
};
