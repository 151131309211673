import { IconButton } from '@mui/material';
import styles from './CommunityLink.module.scss';
import { Delete, Edit } from '@mui/icons-material';

export const CommunityLink = ({ link, onEdit, onDelete }) => {
  return (
    <div className={styles.link} onClick={onEdit}>
      <div className={styles.avatar} style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}communities/links/${link.id}/avatars/medium?${link.avatar}')` }} />
      <div className={styles.details}>
        <div className={styles.title}>{link.title}</div>
        <div className={styles.url}>{link.url}</div>
      </div>
      <div className={styles.actions}>
        <IconButton className={styles.edit} size="small" onClick={onEdit}>
          <Edit size="small" />
        </IconButton>
        <IconButton className={styles.delete} size="small" onClick={ev => {
          ev.stopPropagation();
          onDelete();
        }}>
          <Delete size="small" />
        </IconButton>
      </div>
    </div>
  );
};
