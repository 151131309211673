import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { useEffect, useRef } from 'react';
import styles from './AppDatetimePicker.module.scss';
import moment from 'moment';

export const AppDatetimePicker = ({ name, error, placeholder, value, onChange, onBlur, ...props }) => {
  const inputRef = useRef();

  const valueChanged = newValue => {
    setTimeout(() => {
      if (inputRef.current.value === 'DD.MM.YYYY hh:mm') {
        inputRef.current.value = null;
      }
      onChange({
        target: inputRef.current
      });
    }, 0);
  };

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.classList.add(styles.input);
    inputRef.current.placeholder = placeholder || 'Дата';
    inputRef.current.value = null;
    inputRef.current.addEventListener('blur', onBlur);
  }, [inputRef]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ruLocale}
    >
      <DateTimePicker
        name={name}
        value={(value && value !== 'DD.MM.YYYY hh:mm') ? moment(value, 'DD.MM.YYYY HH:mm').toDate() : null}
        onChange={valueChanged}
        className={`${styles.picker} ${'app-datetime-picker'} ${error ? 'app-datetime-picker--error' : ''}`}
        orientation='landscape'
        inputRef={inputRef}
        {...props}
      />
    </LocalizationProvider>
  );
};
