import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './InviteExistingModal.module.scss';
import { Close } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { AppButton } from '@ui/AppButton/AppButton';
import { useEffect, useState } from 'react';
import { InviteModal } from '../ManagementModal/InviteModal/InviteModal';
import { api } from '@lib/api';
import { useDispatch } from 'react-redux';
import { fetchManagement } from '../../store/club';
import { SuccessModal } from '@ui/SuccessModal/SuccessModal';

export const InviteExistingModal = ({ fromCard }) => {
  const [showInviteConfirmation, setShowInviteConfirmation] = useState(false);
  const [card, setCard] = useState(null);
  const [showSuccessInvite, setShowSuccessInvite] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const closeModal = () => {
    navigate(`/club/`);
  };

  const fetchCard = async () => {
    const response = await api.get(`/dashboard/club/cards/${params.cardId}`);
    setCard(response.data);
  };

  const invite = async () => {
    await api.post(`/dashboard/club/invite-existing/`, {
      cardId: +params.cardId,
    });
    dispatch(fetchManagement());
    setShowInviteConfirmation(false);
    setShowSuccessInvite(true);
  };

  useEffect(() => {
    fetchCard();
  }, [params]);

  if (!card) return null;

  const { firstName, lastName } = card?.common?.about;

  return (
    <>
      <Dialog open={true} onClose={closeModal} className="wide-dialog">
        <DialogTitle>
          Пользователь уже существует
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close color="disabled" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ width: '400px', overflowX: 'hidden' }}
          className="paddingless"
        >
          <div className={styles.modal}>
            <p>
              Пользователь{' '}
              <span className={styles.name}>
                {firstName} {lastName}
              </span>{' '}
              уже зарегистрирован на платформе. {!fromCard ? 'Карточка резидента добавлена в вашу базу.' : ''}
            </p>
            <p>
              Для синхронизаций данных с профилем резидента отправьте
              ему приглашение присоединиться к сообществу.
            </p>
          </div>
        </DialogContent>
        <DialogActions className={styles.footer}>
          <AppButton
            color="secondary"
            variant="contained"
            onClick={invite}
          >
            Отправить приглашение
          </AppButton>
        </DialogActions>
      </Dialog>
      {showInviteConfirmation && (
        <InviteModal
          isName
          items={[{ name: `${firstName} ${lastName}` }]}
          onCancel={() => setShowInviteConfirmation(false)}
          onSend={invite}
        />
      )}
      {showSuccessInvite && <SuccessModal onClose={closeModal} />}
    </>
  );
};
