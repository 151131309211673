import { AppButton } from '../../../../components/ui/AppButton/AppButton';
import styles from './StartBanner.module.scss';

export const StartBanner = ({ title, Icon, Image, description, buttonLabel, url }) => {
  return <div className={styles.banner}>
    <div className={styles.details}>
      <div className={styles.header}>
        <div className={styles.icon}><Icon /></div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.description}>{description}</div>
      <div className={styles.button}>
        <AppButton>{buttonLabel}</AppButton>
      </div>
    </div>
    <div className={styles.image}>
      <Image />
    </div>
  </div>;
};
