import styles from './ColorItem.module.scss';
import CheckIcon from '@mui/icons-material/Check';
import chroma from "chroma-js";

export const ColorItem = ({ color, checked, onClick }) => {
  const clr = chroma(color);
  const reversed = clr.lch()[0] > 80;
  const alpha = clr.alpha(0.2).css()

  const checkedIcon = (
    <div className={styles.checked} data-reversed={reversed ? 'reversed' : ''}>
      <CheckIcon />
    </div>
  );

  return (
    <div
      onClick={() => onClick && onClick()}
      className={styles.color}
      data-checked={checked ? 'checked' : ''}
      style={{ background: color, outlineColor: alpha }}>
      {checked && checkedIcon}
    </div>
  )
};
