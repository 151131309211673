import { useEffect, useState } from 'react';
import styles from './SidebarTab.module.scss';
import {
  Error,
  FmdBad,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { SidebarTabs } from '../SidebarTabs/SidebarTabs';

export const SidebarTab = ({
  icon,
  title,
  isActive,
  onClick,
  count,
  items,
  isSub,
  value,
  isExpanded = false,
  borderless = false,
  isInvalid,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const [sub, setSub] = useState('');

  const active = isActive || items?.map((item) => item.name)?.includes(value);

  const Icon = icon;

  const clicked = () => {
    if (items) {
      setExpanded(!expanded);
    } else {
      onClick();
    }
  };

  return (
    <>
      <div
        data-sidebar-tab="tab"
        data-borderless={borderless ? 'borderless' : ''}
        data-sub={isSub ? 'sub' : ''}
        data-expanded={expanded ? 'expanded' : ''}
        data-parent={items ? 'parent' : ''}
        className={styles.tab}
        data-active={active ? 'active' : ''}
        onClick={clicked}
        data-invalid={isInvalid ? 'invalid' : ''}
      >
        <div className={styles.icon}>
          <Icon color={active ? '#2499ef' : '#96abc4'} />
        </div>
        <div className={styles.title}>
          <span>{title}</span>
          {isInvalid && (
            <div className={styles.invalid}>
              <Error fontSize="small" color="error" />
            </div>
          )}
        </div>
        {items && (
          <div className={styles.expand}>
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
        )}
        {!!count && (
          <div className={styles.count}>
            <span>{count}</span>
          </div>
        )}
      </div>
      {items && expanded && (
        <div className={styles.sub}>
          <SidebarTabs
            tabs={items}
            isSub={true}
            value={value}
            onChange={onClick}
          />
        </div>
      )}
    </>
  );
};
