export const UploadPhotoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 10.2502C12.1989 10.2502 12.3897 10.3292 12.5303 10.4698C12.671 10.6105 12.75 10.8013 12.75 11.0002V12.2502H14C14.1989 12.2502 14.3897 12.3292 14.5303 12.4698C14.671 12.6105 14.75 12.8013 14.75 13.0002C14.75 13.1991 14.671 13.3898 14.5303 13.5305C14.3897 13.6712 14.1989 13.7502 14 13.7502H12.75V15.0002C12.75 15.1991 12.671 15.3898 12.5303 15.5305C12.3897 15.6712 12.1989 15.7502 12 15.7502C11.8011 15.7502 11.6103 15.6712 11.4697 15.5305C11.329 15.3898 11.25 15.1991 11.25 15.0002V13.7502H10C9.80109 13.7502 9.61032 13.6712 9.46967 13.5305C9.32902 13.3898 9.25 13.1991 9.25 13.0002C9.25 12.8013 9.32902 12.6105 9.46967 12.4698C9.61032 12.3292 9.80109 12.2502 10 12.2502H11.25V11.0002C11.25 10.8013 11.329 10.6105 11.4697 10.4698C11.6103 10.3292 11.8011 10.2502 12 10.2502Z"
        fill="#A1A4AF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.778 21.0002H14.222C17.343 21.0002 18.904 21.0002 20.025 20.2652C20.5087 19.9481 20.9253 19.539 21.251 19.0612C22 17.9612 22 16.4282 22 13.3642C22 10.2992 22 8.76717 21.251 7.66717C20.9253 7.18928 20.5087 6.78017 20.025 6.46317C19.305 5.99017 18.403 5.82117 17.022 5.76117C16.363 5.76117 15.796 5.27117 15.667 4.63617C15.5684 4.17104 15.3123 3.7542 14.9418 3.45612C14.5714 3.15803 14.1095 2.99698 13.634 3.00017H10.366C9.378 3.00017 8.527 3.68517 8.333 4.63617C8.204 5.27117 7.637 5.76117 6.978 5.76117C5.598 5.82117 4.696 5.99117 3.975 6.46317C3.49162 6.78023 3.07537 7.18934 2.75 7.66717C2 8.76717 2 10.2992 2 13.3642C2 16.4282 2 17.9602 2.749 19.0612C3.073 19.5372 3.489 19.9462 3.975 20.2652C5.096 21.0002 6.657 21.0002 9.778 21.0002ZM16 13.0002C16 14.061 15.5786 15.0785 14.8284 15.8286C14.0783 16.5787 13.0609 17.0002 12 17.0002C10.9391 17.0002 9.92172 16.5787 9.17157 15.8286C8.42143 15.0785 8 14.061 8 13.0002C8 11.9393 8.42143 10.9219 9.17157 10.1717C9.92172 9.4216 10.9391 9.00017 12 9.00017C13.0609 9.00017 14.0783 9.4216 14.8284 10.1717C15.5786 10.9219 16 11.9393 16 13.0002ZM18 9.25017C17.8011 9.25017 17.6103 9.32919 17.4697 9.46984C17.329 9.61049 17.25 9.80126 17.25 10.0002C17.25 10.1991 17.329 10.3898 17.4697 10.5305C17.6103 10.6712 17.8011 10.7502 18 10.7502H19C19.1989 10.7502 19.3897 10.6712 19.5303 10.5305C19.671 10.3898 19.75 10.1991 19.75 10.0002C19.75 9.80126 19.671 9.61049 19.5303 9.46984C19.3897 9.32919 19.1989 9.25017 19 9.25017H18Z"
        fill="#A1A4AF"
      />
    </svg>
  );
};
