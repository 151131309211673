import {
  FormControl,
  InputAdornment,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { CommunityAvatar } from '../CommunityAvatar/ComunityAvatar';
import styles from '../FormPreview.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  communityPageSlice,
  finishEdit,
  saveData,
  updateUnsavedData,
} from '../../store/community-page.slice';
import { AppInput } from '../../../../components/ui/AppInput/AppInput';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ColorPalette } from '../../../../components/ui/Colors/ColorPalette/ColorPalette';
import { getGradientBackground } from '../../lib/background';
import { CommunityMembers } from '../CommunityMembers/CommunityMembers';
import { FieldError } from '../FieldError/FieldError';
import { Formik } from 'formik';
import { validatePageForm } from '../../lib/validation';
import { SimpleOptions } from '../../../../components/ui/SimpleOptions/SimpleOptions';
import { formatTelegram } from '../../../../util/format';
import { Field } from '../../../../components/ui/Field/Field';
import { AppSelect } from '../../../../components/ui/AppSelect/AppSelect';
import {
  PAYMENT_PERIOD_DEFAULT,
  PAYMENT_PERIODS,
  PAYMENT_AMOUNT_DEFAULT,
} from '../../../../data/constants';
import { CommunityLinks } from '../../../community-links/components/CommunityLinks/CommunityLinks';

export const Form = forwardRef((props, ref) => {
  const data = useSelector((state) => state.communityPage.data);
  const unsavedData = useSelector((state) => state.communityPage.unsavedData);
  const { title: originalTitle } = useSelector(
    (state) => state.community.community
  );
  const dispatch = useDispatch();
  const submitButtonRef = useRef();
  const hash = window.location.hash?.slice(1) || null;
  const { community } = useSelector((state) => state.community);
  const [bg, setBg] = useState(data.bgColor);
  const [available, setAvailable] = useState(data.availableColors);

  const submitForm = (d) => {
    const data = {
      ...d,
    };
    if (data.telegram) {
      data.telegram = formatTelegram(data.telegram);
    }
    if (data.payment !== 'paid') {
      delete data.period;
      delete data.amount;
    }
    dispatch(updateUnsavedData(data));
    setTimeout(() => {
      dispatch(saveData());
      dispatch(finishEdit());
    }, 0);
  };

  const validateForm = (data) => {
    const errors = validatePageForm(data);
    dispatch(communityPageSlice.actions.setErrors(errors));
    return errors;
  };

  const updateBgColor = (value) => {
    setBg(value);
  };

  const updateAvailableColors = (val) => {
    setAvailable(val);
  };

  const getPeriodValue = (period) => {
    const option = PAYMENT_PERIODS.find((option) => option.value === period);
    return option;
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        submit() {
          submitButtonRef.current.click();
        },
      };
    },
    []
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        updateUnsavedData({
          ...data,
          bgColor: bg,
          availableColors: available,
        })
      );
    }, 50);
  }, [bg]);

  return (
    <div
      className={styles.page}
      style={{ background: getGradientBackground(unsavedData.bgColor) }}
      data-edit={hash === 'edit' ? 'edit' : ''}
    >
      <div className={styles.container}>
        <Formik
          initialValues={{
            title: data.title || originalTitle,
            slogan: data.slogan,
            website: data.website,
            telegram: data.telegram,
            amount: data.amount || PAYMENT_AMOUNT_DEFAULT,
            period: data.period || PAYMENT_PERIOD_DEFAULT,
            about: data.about,
            payment: community.isPaid ? 'paid' : 'free',
            type: community.isPublic ? 'open' : 'closed',
            name: community.name,
          }}
          validate={validateForm}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.section}>
                <div className={styles.avatarAndTitle}>
                  <div className={styles.avatar}>
                    <CommunityAvatar />
                  </div>
                  <div className={styles.title}>
                    <FormControl fullWidth>
                      <AppInput
                        value={values.title}
                        name="title"
                        onChange={handleChange}
                        placeholder="Название сообщества"
                        onBlur={handleBlur}
                        error={!!(touched.title && errors.title)}
                      />
                    </FormControl>
                    {touched.title && errors.title && (
                      <FieldError>{errors.title}</FieldError>
                    )}
                  </div>
                </div>

                <div className={styles.subsection}>
                  <div className={styles.subtitle}>Миссия сообщества</div>
                  <div className={styles.subcontent}>
                    <FormControl fullWidth>
                      <AppInput
                        с
                        value={values.slogan}
                        name="slogan"
                        onChange={handleChange}
                        placeholder="Кратко расскажите о миссии вашего сообщества"
                        onBlur={handleBlur}
                        error={!!!!(touched.slogan && errors.slogan)}
                      />
                    </FormControl>
                    {touched.slogan && errors.slogan && (
                      <FieldError>{errors.slogan}</FieldError>
                    )}
                  </div>
                </div>

                <div className={styles.subsection}>
                  <div className={styles.subtitle}>Адрес</div>
                  <div className={styles.subcontent}>
                    <FormControl fullWidth>
                      <AppInput
                        InputProps={{
                          inputProps: {
                            className: styles.nameInput,
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <span className={styles.nameAdornment}>{process.env.REACT_APP_UNAUTH_REDIRECT.split('/lead/')[0] + '/community/'}</span>

                            </InputAdornment>
                          ),
                        }}
                        value={values.name}
                        name="name"
                        onChange={(ev) => {
                          const val = ev.target.value
                            .replace(/_/g, '-')
                            .replace(/[^a-zA-Z0-9-]/g, '');
                          setFieldValue('name', val);
                        }}
                        onBlur={handleBlur}
                        error={!!!!(touched.name && errors.name)}
                      />
                    </FormControl>
                    {touched.name && errors.name && (
                      <FieldError>{errors.name}</FieldError>
                    )}
                  </div>
                </div>

                <div className={styles.subsection}>
                  <div className={styles.subtitle}>Веб-сайт</div>
                  <div className={styles.subcontent}>
                    <FormControl fullWidth>
                      <AppInput
                        value={values.website}
                        name="website"
                        onChange={handleChange}
                        placeholder="Укажите ссылку на сайт сообщества"
                        onBlur={handleBlur}
                        error={!!(touched.website && errors.website)}
                      />
                    </FormControl>
                    {touched.website && errors.website && (
                      <FieldError>{errors.website}</FieldError>
                    )}
                  </div>
                </div>

                <div className={styles.subsection}>
                  <div className={styles.subtitle}>Телеграм</div>
                  <div className={styles.subcontent}>
                    <FormControl fullWidth>
                      <AppInput
                        value={values.telegram}
                        name="telegram"
                        onChange={handleChange}
                        placeholder="Укажите telegram сообщества"
                        onBlur={handleBlur}
                        error={!!(touched.telegram && errors.telegram)}
                      />
                    </FormControl>
                    {touched.telegram && errors.telegram && (
                      <FieldError>{errors.telegram}</FieldError>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.sectionHeader}>Приватность</div>
                <div className={styles.content}>
                  <p>
                    <SimpleOptions
                      options={[
                        {
                          title: 'Открытое сообщество',
                          name: 'open',
                        },
                        {
                          title: 'Закрытое сообщество',
                          name: 'closed',
                        },
                      ]}
                      value={values.type}
                      onChange={(val) => setFieldValue('type', val)}
                    />
                  </p>
                  <p>
                    В открытое сообщество может вступить любой желающий.
                    <br />
                    Для вступления в закрытое сообщество каждый потенциальный
                    участник проходит обязательную верификацию менеджером
                    сообщества.
                  </p>
                </div>
              </div>

              {values.type === 'closed' && (
                <div className={styles.section}>
                  <div className={styles.sectionHeader}>Монетизация</div>
                  <div className={styles.content}>
                    <p>
                      <SimpleOptions
                        options={[
                          {
                            title: 'Бесплатное сообщество',
                            name: 'free',
                          },
                          {
                            title: 'Платное сообщество',
                            name: 'paid',
                          },
                        ]}
                        value={values.payment}
                        onChange={(val) => setFieldValue('payment', val)}
                      />
                    </p>
                    {values.payment === 'paid' && (
                      <div className={styles.paymentOptions}>
                        <section>
                          <Field label="Сумма">
                            <FormControl fullWidth>
                              <AppInput
                                value={values.amount}
                                onChange={handleChange}
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ fontFamily: 'Roboto' }}
                                    >
                                      <Typography sx={{ fontFamily: 'Roboto' }}>
                                        ₽
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                }}
                                onBlur={handleBlur}
                                name="amount"
                                placeholder="Сумма"
                                error={!!(errors.amount && touched.amount)}
                              />
                            </FormControl>
                          </Field>
                        </section>
                        <section>
                          <Field label="Период">
                            <FormControl fullWidth>
                              <AppSelect
                                placeholder="Период подписки"
                                value={getPeriodValue(values.period)}
                                options={PAYMENT_PERIODS}
                                onChange={(item) => {
                                  setFieldValue('period', item.value);
                                }}
                              />
                            </FormControl>
                          </Field>
                        </section>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className={styles.section}>
                <div className={styles.sectionHeader}>О нас</div>
                <div className={styles.subsection}>
                  <div
                    className={styles.subcontent}
                    style={{ marginBottom: '10px' }}
                  >
                    <FormControl fullWidth>
                      <AppInput
                        value={values.about}
                        onChange={handleChange}
                        name="about"
                        multiline
                        rows={3}
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            minRows: 3, // Минимальное количество строк
                            maxRows: 12, // Максимальное количество строк до включения скролла
                          },
                        }}
                        sx={{
                          overflow: 'auto', // Включаем скролл при превышении maxRows
                        }}
                        maxRows={12}
                        onBlur={handleBlur}
                        error={!!(touched.about && errors.about)}
                      />
                    </FormControl>
                    {touched.about && errors.about && (
                      <FieldError>{errors.about}</FieldError>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.sectionHeader}>Закрепленные ссылки</div>
                <div className={styles.content}>
                  <CommunityLinks communityId={community.id} />
                </div>
              </div>

              <button
                type="submit"
                style={{ display: 'none' }}
                ref={submitButtonRef}
              ></button>
            </form>
          )}
        </Formik>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Цвет страницы</div>
          <div className={styles.subcontent}>
            <ColorPalette
              colors={available}
              onAvailableChanged={updateAvailableColors}
              value={bg}
              onChange={updateBgColor}
            />
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>Команда сообщества</div>
          <div className={styles.subcontent}>
            <CommunityMembers editable={true} />
          </div>
        </div>
      </div>
    </div>
  );
});
