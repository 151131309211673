import { IconButton, Snackbar } from '@mui/material';
import { withStyles } from '@mui/styles';
import { GridCloseIcon } from '@mui/x-data-grid';

const styles = {
  snackbarStyleViaContentProps: {
    backgroundColor: '#ff0000',
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: '#ff0000',
    color: 'white',
  },
};

const ErrSnack = ({ onClose, classes, error }) => {
  const message = error;

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <GridCloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={true}
      color="error"
      autoHideDuration={5000}
      onClose={onClose}
      message={message}
      action={action}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      ContentProps={{
        'aria-describedby': 'message-id',
        className: classes.snackbarStyleViaContentProps,
      }}
    />
  );
};

export const ErrorSnack = withStyles(styles)(ErrSnack);
