import { ContactInput } from '../ContactInput/ContactInput';
import styles from './TelegramInput.module.scss';

export const TelegramInput = ({ error, ...props }) => {
  return (
    <div className={styles.input} data-error={error ? 'error' : ''}>
      <ContactInput error={error} {...props} />
    </div>
  );
};
