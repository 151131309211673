export const CommunityCalendarIcon = () => {
  return <svg width="187" height="124" viewBox="0 0 187 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="187" height="124" rx="4" fill="#F7FAFB" />
    <path d="M61 36.633C61 34.2685 62.9569 32.3517 65.3709 32.3517H122.629C125.043 32.3517 127 34.2685 127 36.633V92.7187C127 95.0832 125.043 97 122.629 97H65.3709C62.9569 97 61 95.0832 61 92.7187V36.633Z" fill="white" />
    <path d="M61 36.633C61 34.2685 62.9569 32.3517 65.3709 32.3517H122.629C125.043 32.3517 127 34.2685 127 36.633V42.1988H61V36.633Z" fill="#CFEAF9" />
    <path d="M70.1788 28.7125C70.1788 27.7667 70.9616 27 71.9272 27H74.7682C75.7338 27 76.5166 27.7667 76.5166 28.7125V35.9908C76.5166 36.9366 75.7338 37.7034 74.7682 37.7034H71.9272C70.9616 37.7034 70.1788 36.9366 70.1788 35.9908V28.7125Z" fill="#2499EF" />
    <path d="M111.483 28.7125C111.483 27.7667 112.266 27 113.232 27H116.073C117.038 27 117.821 27.7667 117.821 28.7125V35.9908C117.821 36.9366 117.038 37.7034 116.073 37.7034H113.232C112.266 37.7034 111.483 36.9366 111.483 35.9908V28.7125Z" fill="#2499EF" />
    <path d="M66.0265 51.8318C66.0265 50.886 66.8093 50.1193 67.7748 50.1193H76.9536C77.9192 50.1193 78.702 50.886 78.702 51.8318V60.8226C78.702 61.7684 77.9192 62.5352 76.9536 62.5352H67.7748C66.8093 62.5352 66.0265 61.7684 66.0265 60.8226V51.8318Z" fill="#CFEAF9" />
    <path d="M66.0265 66.8165C66.0265 65.8707 66.8093 65.104 67.7748 65.104H76.9536C77.9192 65.104 78.702 65.8707 78.702 66.8165V75.8073C78.702 76.7531 77.9192 77.5199 76.9536 77.5199H67.7748C66.8093 77.5199 66.0265 76.7531 66.0265 75.8073V66.8165Z" fill="#CFEAF9" />
    <path d="M66.0265 81.8012C66.0265 80.8554 66.8093 80.0887 67.7748 80.0887H76.9536C77.9192 80.0887 78.702 80.8554 78.702 81.8012V90.792C78.702 91.7379 77.9192 92.5046 76.9536 92.5046H67.7748C66.8093 92.5046 66.0265 91.7379 66.0265 90.792V81.8012Z" fill="#CFEAF9" />
    <path d="M80.4503 51.8318C80.4503 50.886 81.2331 50.1193 82.1987 50.1193H91.3775C92.3431 50.1193 93.1258 50.886 93.1258 51.8318V60.8226C93.1258 61.7684 92.3431 62.5352 91.3775 62.5352H82.1987C81.2331 62.5352 80.4503 61.7684 80.4503 60.8226V51.8318Z" fill="#CFEAF9" />
    <path d="M80.4503 66.8165C80.4503 65.8707 81.2331 65.104 82.1987 65.104H91.3775C92.3431 65.104 93.1258 65.8707 93.1258 66.8165V75.8073C93.1258 76.7531 92.3431 77.5199 91.3775 77.5199H82.1987C81.2331 77.5199 80.4503 76.7531 80.4503 75.8073V66.8165Z" fill="#CFEAF9" />
    <path d="M94.8742 51.8318C94.8742 50.886 95.6569 50.1193 96.6225 50.1193H105.801C106.767 50.1193 107.55 50.886 107.55 51.8318V60.8226C107.55 61.7684 106.767 62.5352 105.801 62.5352H96.6225C95.6569 62.5352 94.8742 61.7684 94.8742 60.8226V51.8318Z" fill="#CFEAF9" />
    <path d="M94.8742 66.8165C94.8742 65.8707 95.6569 65.104 96.6225 65.104H105.801C106.767 65.104 107.55 65.8707 107.55 66.8165V75.8073C107.55 76.7531 106.767 77.5199 105.801 77.5199H96.6225C95.6569 77.5199 94.8742 76.7531 94.8742 75.8073V66.8165Z" fill="#CFEAF9" />
    <path d="M109.298 51.8318C109.298 50.886 110.081 50.1193 111.046 50.1193H120.225C121.191 50.1193 121.974 50.886 121.974 51.8318V60.8226C121.974 61.7684 121.191 62.5352 120.225 62.5352H111.046C110.081 62.5352 109.298 61.7684 109.298 60.8226V51.8318Z" fill="#CFEAF9" />
    <path d="M109.298 66.8165C109.298 65.8707 110.081 65.104 111.046 65.104H120.225C121.191 65.104 121.974 65.8707 121.974 66.8165V75.8073C121.974 76.7531 121.191 77.5199 120.225 77.5199H111.046C110.081 77.5199 109.298 76.7531 109.298 75.8073V66.8165Z" fill="#CFEAF9" />
  </svg>
};
