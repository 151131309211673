import { SectionHeader } from '../../ResidentModal/SectionHeader/SectionHeader';
import styles from './Payment.module.scss';

export const Payment = () => {
  return (
    <div className={styles.waiting}>
      <SectionHeader
        title="Ожидается оплата"
        description="Мы уже отправили СМС с ссылкой на оплату участникам в этом списке. После оплаты они получат полный доступ к сообществу. Вы также можете назначить любого участника другом сообщества, тогда он сможет получить доступ бесплатно. Управлять статусом друга сообщества можно в разделе «Резидентство» в карточке участника."
      />
    </div>
  );
};
