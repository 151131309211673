import moment from 'moment';

import 'moment/locale/ru';

moment.locale('ru');

export const dateRange = (
  beginDate,
  endDate,
) => {
  if (!beginDate) return 'Не указано';
  const begin = moment(beginDate);
  if (!endDate) return begin.format('DD MMMM YYYY');
  const end = moment(endDate).subtract(1, 'day').add(22, 'hours');
  let date = `${begin.format('DD MMMM YYYY')} – ${end.format(
    'DD MMMM YYYY',
  )}`;
  if (begin.year() === end.year()) {
    if (begin.month() === end.month()) {
      if (begin.date() === end.date()) {
        date = begin.format('DD MMMM YYYY');
      } else {
        date = `${begin.format('DD')} – ${end.format('DD MMMM YYYY')}`;
      }
    } else {
      date = `${begin.format('DD MMMM')} – ${end.format(
        'DD MMMM',
      )} ${begin.format('YYYY')}`;
    }
  }
  return date;
}
