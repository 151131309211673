import { IconButton, Snackbar } from "@mui/material";
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import { hideError } from "../../store/community-page.slice";
import React from "react";
import { GridCloseIcon } from "@mui/x-data-grid";

const styles = {
  snackbarStyleViaContentProps: {
    backgroundColor: "red"
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "red",
    color: "white"
  }
};

const ComSnacks = ({ classes }) => {
  const { error } = useSelector(state => state.communityPage);
  const dispatch = useDispatch();

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => dispatch(hideError())}
      >
        <GridCloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );;

  return <Snackbar
    open={!!error}
    color="error"
    autoHideDuration={5000}
    onClose={() => dispatch(hideError())}
    message={error}
    action={action}
    ContentProps={{
      "aria-describedby": "message-id",
      className: classes.snackbarStyleViaContentProps
    }}
  />
};

export const CommunitySnacks = withStyles(styles)(ComSnacks);
