import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { startProgress } from '../../../store/common';
import {
  fetchCards,
  fetchPagesRange,
  prepareParams,
  sortCards,
  updateCardInArray,
  updateCardsList,
} from '../lib/club';

export const clubSlice = createSlice({
  name: 'club',
  initialState: {
    cards: [],
    page: 1,
    limit: 30,
    total: 0,
    params: {},
    isArchive: false,
    residentStatuses: [],
    management: {
      toInvite: [],
      toVerify: [],
    },
  },
  reducers: {
    setCards(state, action) {
      state.cards = sortCards(action.payload);
    },
    appendCards(state, action) {
      state.cards = sortCards([...state.cards, ...action.payload]);
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    setIsArchive(state, action) {
      state.isArchive = action.payload;
    },
    setManagement(state, action) {
      state.management = {
        toInvite: action.payload.toInvite || [],
        toVerify: action.payload.toVerify || [],
        toPay: action.payload.toPay || [],
      };
    },
    setParams(state, action) {
      state.params = action.payload;
    },
    setResidentStatuses(state, action) {
      state.residentStatuses = action.payload;
    },
    updateResidentStatus(state, action) {
      const [oldValue, newValue] = action.payload;
      const items = [...state.residentStatuses];
      const index = items.indexOf(oldValue);
      if (index < 0) {
        return;
      }
      items.splice(index, 1, newValue);
      state.residentStatuses = items;
    },
    removeResidentStatus(state, action) {
      state.residentStatuses = state.residentStatuses.filter(
        (status) => status !== action.payload
      );
    },
    createResidentStatus(state, action) {
      state.residentStatuses = [...state.residentStatuses, action.payload];
    },
  },
});

export const updateCardInList =
  ({ cardId, common }) =>
  async (dispatch, getState) => {
    const {
      club: { cards },
    } = getState();
    dispatch(
      clubSlice.actions.setCards(updateCardInArray(cards, cardId, common))
    );
  };

export const fetchCardsPage =
  (search = null, isArchive = null, filters = null, isNext = false) =>
  async (dispatch, getState) => {
    dispatch(startProgress());
    dispatch(clubSlice.actions.setPage(1));
    dispatch(clubSlice.actions.setIsArchive(isArchive));
    const state = getState();
    const page = isNext ? state.club.page + 1 : 1;
    const params = prepareParams({
      page: page,
      limit: state.club.limit,
      search: search || state.club.search,
      isArchive:
        typeof isArchive === 'boolean' ? isArchive : !!state.club.isArchive,
      filters: filters || state.club.filters,
    });
    const { cards, total, params: newParams } = await fetchCards(params);
    dispatch(clubSlice.actions.setParams(newParams));
    dispatch(
      isNext
        ? clubSlice.actions.appendCards(cards)
        : clubSlice.actions.setCards(cards)
    );
    dispatch(clubSlice.actions.setTotal(total));
  };

export const refetchCards = () => async (dispatch, getState) => {
  const state = getState();
  const { cards: newCards } = await fetchPagesRange(state.club.params);
  dispatch(
    clubSlice.actions.setCards(updateCardsList(state.club.cards, newCards))
  );
};

export const fetchManagement = () => async (dispatch) => {
  const [
    {
      data: { data: toInvite },
    },
    {
      data: { data: toVerify },
    },
    {
      data: { data: toPay },
    },
  ] = await Promise.all(
    ['invite-pending', 'verification-pending', 'payment-pending'].map(
      async (memberCondition) =>
        await api.get(`/v2/dashboard/club/cards/`, {
          params: {
            page: 1,
            limit: 100,
            memberCondition,
          },
        })
    )
  );
  dispatch(clubSlice.actions.setManagement({ toInvite, toVerify, toPay }));
  dispatch(refetchCards());
};

export const fetchResidentStatuses = () => async (dispatch) => {
  const response = await api.get(`/dashboard/club/resident-status/`);
  dispatch(clubSlice.actions.setResidentStatuses(response.data));
};

export const putResidentStatuses = () => async (dispatch, getState) => {
  const { residentStatuses } = getState().club;
  await api.patch(`/dashboard/club/resident-status/`, {
    items: residentStatuses,
  });
};

export const updateResidentStatus =
  (oldValue, newValue) => async (dispatch) => {
    dispatch(clubSlice.actions.updateResidentStatus([oldValue, newValue]));
    dispatch(putResidentStatuses());
  };

export const removeResidentStatus = (status) => async (dispatch) => {
  dispatch(clubSlice.actions.removeResidentStatus(status));
  dispatch(putResidentStatuses());
};

export const createResidentStatus = (status) => async (dispatch) => {
  dispatch(clubSlice.actions.createResidentStatus(status));
  dispatch(putResidentStatuses());
};
