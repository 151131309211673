import { Email, Phone, Telegram } from '@mui/icons-material';
import styles from './ResidentCard.module.scss';
import { sample } from 'lodash';
import { ACTUAL_REQUESTS } from '../../data/actual-requests';
import { ResidentAvatar } from '../ResidentAvatar/ResidentAvatar';

const IS_DEMO = false;

export const ResidentCard = ({ card, onClick }) => {
  const getActualRequest = () => {
    const val = IS_DEMO ? sample(ACTUAL_REQUESTS).label : card.actualRequest;
    if (val.length >= 50) {
      return val.slice(0, 50) + '...';
    }
    return val;
  };

  const getCommunityTitle = () => {
    if (card.community.length <= 30) return card.community;
    return card.community.slice(0, 32) + '...';
  };

  return (
    <div
      className={styles.card}
      onClick={onClick}
      data-user={card.hasUser ? 'user' : ''}
      data-archive={card.isArchive ? 'archive' : ''}
    >
      <div className={styles.header}>
        <div className={styles.avatar}>
          <ResidentAvatar avatar={card.avatar} />
        </div>
        <div className={styles.name}>
          <div>{card.firstName}</div>
          <div>{card.lastName}</div>
        </div>
      </div>
      <div className={styles.community}>{getCommunityTitle()}</div>
      {/* <div className={styles.contact}>
        <div className={styles.icon}>
          <Email />
        </div>
        <div className={styles.value}>{card.email}</div>
      </div> */}
      <div className={styles.contact}>
        <div className={styles.icon}>
          <Phone />
        </div>
        <div className={styles.value}>{card.phone}</div>
      </div>
      {card.telegram && (
        <div className={styles.contact}>
          <div className={styles.icon}>
            <Telegram />
          </div>
          <div className={styles.value}>
            <a
              target="_blank"
              href={`https://t.me/${card.telegram.slice(1)}`} rel="noreferrer"
              onClick={ev => ev.stopPropagation()}
            >{`${card.telegram}`}</a>
          </div>
        </div>
      )}
      <div className={styles.footer}>
        <div className={styles.city}>{card.city?.split('|')[0] || 'Город не указан'}</div>
        <div className={styles.role} data-manager={card.isManager ? 'manager' : ''}>{card.isManager ? 'Менеджер' : 'Участник'}</div>
      </div>
    </div>
  );
};
