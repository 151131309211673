import { Page } from '@layouts/Page/Page';
import { Heading } from '@layouts/Heading/Heading';
import {
  Add,
  CalendarToday,
} from '@mui/icons-material';

import styles from './Calendar.module.scss';
import { Events } from '../../features/calendar/components/Events/Events';
import { usePageTitle } from '../../hooks/usePageTitle';
import { AppButton } from '../../components/ui/AppButton/AppButton';
import { Link } from 'react-router-dom';

export const CalendarPage = () => {
  const pageTitle = usePageTitle();

  pageTitle('Календарь событий');

  return (
    <Page
      breadcrumbs={[
        {
          title: 'Календарь событий',
          icon: CalendarToday,
          url: '/calendar/',
        },
      ]}
    >
      <div className={styles.calendar}>
        <Heading icon={CalendarToday} title="Календарь событий">
          <Link to="/calendar/add/">
            <AppButton startIcon={<Add />}>Создать событие</AppButton>
          </Link>
        </Heading>
        <Events />
      </div>
    </Page>
  );
};
