export const EVENT_REPEAT_OPTIONS = [
  { value: 'no', label: 'Не повторять' },
  { value: 'day', label: 'Каждый день' },
  { value: 'week', label: 'Каждую неделю' },
  { value: 'month', label: 'Каждый месяц' },
  { value: 'year', label: 'Каждый год' },
];

export const EVENT_FORMAT_OPTIONS = [
  { value: 'online', label: 'Онлайн' },
  { value: 'offline', label: 'Офлайн' },
];

export const EVENT_CONDITIONS_OPTIONS = [
  { value: 'free', label: 'Бесплатно' },
  { value: 'paid', label: 'Платно' },
];
