import { Link } from 'react-router-dom';
import styles from './RelatedCommunity.module.scss';
import { IconButton } from '@mui/material';
import { Delete, Edit, OpenInNew } from '@mui/icons-material';
import { DEFAULT_AVATAR } from '../../../../data/constants';

export const RelatedCommunity = ({ avatar, title, managers, onOpen, onEdit, onRemove }) => {
  return (
    <div className={styles.community} onClick={onEdit}>
      <div className={styles.avatar} style={{ backgroundImage: avatar ? `url(${avatar})` : `url(${DEFAULT_AVATAR})` }}></div>
      <div className={styles.details}>
        <div className={styles.title}>{title}</div>
        <div className={styles.managers}>
          <span className={styles.label}>Менеджер{managers.length === 1 ? '' : 'ы'}:</span>
          &nbsp;
          {!managers.length && 'нет ни одного менеджера'}
          {managers.map((manager, i) => (
            <>
              <Link
                onClick={ev => {
                  ev.stopPropagation();
                }}
                className={styles.manager}
                to={`/club/${manager.cardId}?redirectTo=${encodeURIComponent(`/club/related/`)}`}>
                <span>{manager.firstName}</span>
                &nbsp;
                <span>{manager.lastName}</span>
              </Link>
              {i < (managers.length - 1) ? <span>, </span> : ''}
            </>
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        <div className={styles.open}>
          <IconButton onClick={onOpen}>
            <OpenInNew />
          </IconButton>
        </div>
        <div className={styles.edit}>
          <IconButton onClick={onEdit}>
            <Edit />
          </IconButton>
        </div>
        <div className={styles.remove}>
          <IconButton onClick={onRemove}>
            <Delete />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
