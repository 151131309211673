import moment from 'moment';
import { generateRrule } from 'rruler';

export const compileRrule = (startStr, endStr, repeat) => {
  if (!repeat || repeat === 'no') return null;
  const start = moment(startStr, 'DD.MM.YYYY HH:mm');
  const end = moment(endStr, 'DD.MM.YYYY HH:mm');
  const data = {
    each: repeat,
    start,
  };
  const rrule = generateRrule(data);
  return rrule;
};
