import styles from './EventCard.module.scss';
import {
  ContentCopy,
  Lock,
  LockOpen,
} from '@mui/icons-material';
import { CheckBox } from '@ui/CheckBox/CheckBox';
import { dateRange } from '../../../../util/datetime';
import { Tooltip } from '@mui/material';
import { formatRrule, rruleDateRange } from 'rruler';

export const EventCard = ({
  event,
  checked,
  onCheck,
  onClick,
  showCheckbox,
}) => {
  const recurring = formatRrule(event.rrule);

  const title = event.title || '';

  const cardChecked = (ev) => {
    onCheck && onCheck(ev.target.value);
  };

  const getTitle = () => {
    if (title.length <= 60) return title;
    return title.slice(0, 60) + '...';
  };

  const checkboxClicked = (ev) => {
    ev.stopPropagation();
  };

  const getDateRange = () => {
    if (!event.rrule) {
      return dateRange(event.start, event.finish);
    }
    const dates = rruleDateRange(event.rrule, event.start, event.finish);
    return dateRange(dates[0], dates[1]);
  };

  return (
    <div className={styles.card} onClick={onClick}>
      <div className={styles.title}>{getTitle()}</div>
      <div className={styles.recurring}>{recurring}</div>
      <div className={styles.date}>{getDateRange()}</div>
      <div className={styles.city}>{event.city || 'Город не указан'}</div>
      <div className={styles.format}>{event.format === 'online' ? 'Онлайн' : 'Офлайн'}</div>
      <div className={styles.type}>{event.type || 'Событие сообщества'}</div>
      <a
        href={`${process.env.REACT_APP_API_URL
          }dashboard/calendar/redirect-to-event?eventId=${event.id
          }&leadUrl=${encodeURIComponent(process.env.REACT_APP_UNAUTH_REDIRECT)}`}
        target="_blank"
        rel="noreferrer"
        className={styles.link}
      >
        <div className={styles.icon}>
          <ContentCopy color="disabled" fontSize="small" />
        </div>
        <div className={styles.label}>Событие на платформе</div>
      </a>
      <div className={styles.restricted}>
        {event.restricted ? (
          <Tooltip title="Закрытое мероприятие">
            <Lock fontSize="medium" color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title="Открытое мероприятие">
            <LockOpen fontSize="medium" color="disabled" />
          </Tooltip>
        )}
      </div>
      <div
        className={styles.checkbox}
        data-checked={checked ? 'checked' : ''}
        onClick={checkboxClicked}
      >
        <CheckBox checked={!!checked} onChange={cardChecked} />
      </div>
    </div>
  );
};
