import { CalendarFilters } from '../CalendarFilters/CalendarFilters';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { EventCard } from '../EventCard/EventCard';
import { api } from '@lib/api';
import {
  calendarSlice,
  fetchEvents,
  nextPage,
} from '@features/calendar/store/calendar';

import styles from './Events.module.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import { startProgress } from '../../../../store/common';
import { RemoveConfirmation } from '../../../../components/ui/RemoveConfirmation/RemoveConfirmation';
import plural from 'ooi/plural';

export const Events = ({ children }) => {
  const filters = useSelector((state) => state.calendar.filters);
  const events = useSelector((state) => state.calendar.events);
  const page = useSelector((state) => state.calendar.page);
  const limit = useSelector((state) => state.calendar.limit);
  const total = useSelector((state) => state.calendar.total);
  const loading = useSelector((state) => state.calendar.loading);
  const [bottom, setBottom] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onBottom = () => {
    const pagesCount = Math.ceil(total / limit);
    if (page >= pagesCount) return;
    dispatch(calendarSlice.actions.setLoading(true));
    dispatch(nextPage());
  };

  const onWheel = () => {
    if (loading) return;
    const d = document.body.offsetHeight - window.innerHeight - window.scrollY;
    if (d > 200) {
      setBottom(false);
    } else {
      setBottom(true);
    }
  };

  const eventClicked = (event) => {
    navigate(`/calendar/${event.id}/`);
  };

  const filtersChanged = (filters) => {
    dispatch(calendarSlice.actions.setFilters(filters));
  };

  const toggleChecked = event => {
    setChecked(prev => {
      if (prev.includes(event.id)) {
        return prev.filter(id => id !== event.id);
      }
      return [...prev, event.id];
    });
  };

  const proceedDeletion = async () => {
    dispatch(startProgress());
    const ids = [...checked];
    setChecked([]);
    for (const id of ids) {
      await api.delete(`/calendar/events/${id}`);
    }
    dispatch(fetchEvents());
    setShowDeleteConfirmation(false);
  };

  const deleteEvents = () => {
    setShowDeleteConfirmation(true);
  };

  useEffect(() => {
    if (!bottom) {
      return;
    }
    onBottom();
  }, [bottom]);

  useEffect(() => {
    dispatch(calendarSlice.actions.setPage(1));
    dispatch(fetchEvents());
  }, [filters]);

  useEffect(() => {
    fetchEvents();
    window.addEventListener('wheel', onWheel);
    return () => {
      window.removeEventListener('wheel', onWheel);
    };
  }, []);

  return (
    <>
      <div className={styles.events}>
        <Box sx={{ marginBottom: 3 }}>
          <CalendarFilters filters={filters} onChange={filtersChanged} showDeleteButton={!!checked.length} onDelete={deleteEvents} onReset={() => setChecked([])} />
        </Box>
        {!!children && <Box sx={{ marginBottom: 3 }}>{children}</Box>}
        <div className={styles.items}>
          {events.map((event) => (
            <EventCard
              key={event.id}
              showCheckbox
              onClick={() => eventClicked(event)}
              event={event}
              onCheck={() => toggleChecked(event)}
              checked={checked.includes(event.id)}
            />
          ))}
        </div>
        <Outlet />
      </div>
      {showDeleteConfirmation && (
        <RemoveConfirmation
          title="Удалить события?"
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={proceedDeletion}>
          Вы действительно хотите удалить {checked.length} {plural(checked.length, ['событие', 'события', 'событий'])}?
          <br />
          Это действие нельзя будет отменить.
        </RemoveConfirmation>
      )}
    </>
  );
};
