import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { AppButton } from '../AppButton/AppButton';
import styles from './RemoveConfirmation.module.scss';

export const RemoveConfirmation = ({ title, children, onCancel, onConfirm, width = 448 }) => {
  return (
    <Dialog
      open={true}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title || 'Удаление'}</DialogTitle>
      <DialogContent sx={{ width: `${width}px` }}>
        <div className={styles.description}>{children || 'Вы действительно хотите удалить?'}</div>
      </DialogContent>
      <DialogActions>
        <AppButton onClick={onCancel} color="secondary" variant="contained">
          Отмена
        </AppButton>
        <AppButton
          onClick={onConfirm}
          autoFocus
          color="error"
          variant="contained"
        >
          Удалить
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};
