const selectStyles = ({ error = false }) => {
  const $primary = '#2499EF';

  const container = {
    borderRadius: '8px',
    border: `1px solid ${error ? 'red' : '#DCE5F0'}`,
    background: '#FFF',
    fontSize: '13px',
    padding: '7px 7px 7px 5px',
    maxWidth: '100%',
    outline: 'none',
  };

  const disabledContainer = {
    background: '#f1f6f9',
    color: '#335474',
  };

  const tag = {
    borderRadius: '4px',
    background: '#2499ef',
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 'normal',
    padding: '2.5px 5px 3px 5px',
    '& div[role="button"]': {
      cursor: 'pointer',
      '&:hover': {
        background: '#208cdc',
        color: 'inherit',
      },
    },
  };

  const disabledTag = {
    background: 'rgba(0, 0, 0, 0.02)',
  };

  const placeholder = {
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0em',
    color: '#AFB3CB',
  };

  return {
    control: (baseStyles, state) => {
      let styles = {
        ...baseStyles,
        ...container,
      };
      if (state.isDisabled) {
        styles = Object.assign(styles, disabledContainer);
      }
      return styles;
    },
    multiValue: (baseStyles, state) => {
      let styles = {
        ...baseStyles,
        ...tag,
      };
      if (state.isDisabled) {
        styles = Object.assign(styles, disabledTag);
      }
      return styles;
    },
    multiValueRemove: (base, state) => ({
      ...base,
      color: 'white', // White text color
      ':hover': {
        backgroundColor: 'red',
        color: 'white',
      },
      fontWeight: 400,
      // Align remove button to the left
      order: -1, // Use flexbox 'order' property to move it to the left
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      ...placeholder,
    }),
    multiValueLabel: (baseStyles, state) => {
      let styles = {
        ...baseStyles,
        ...tag,
      };
      if (state.isDisabled) {
        styles = Object.assign(styles, disabledTag);
      }
      return styles;
    },
    option: (baseStyles, state) => {
      return {
        ...baseStyles,
        background: state.isFocused ? $primary : '#fff',
        color: state.isFocused ? '#fff' : '#3F4049',
        fontSize: '14px',
        lineHeight: '15px',
        padding: '13px 16px',
        transition: '0.3s background, 0.3s color',
        '&:hover': {
          background: $primary,
          color: '#fff !important',
        },
        cursor: 'pointer',
      };
    },
    menu: (styles, state) => ({
      ...styles,
    }),
  };
};

// const tagStyles = {
//   background: '#0018a8',
//   color: '#fff',
//   display: 'flex',
//   padding: `0 4px 0 4px !important`,
//   borderRadius: '8px',
//   margin: '2px 4px 2px 0px',
//   height: '31px',
//   alignItems: 'center',
//   textTransform: 'none',
//   fontSize: '14px',
//   fontWeight: 400,
//   lineHeight: '14px',
//   letterSpacing: '0em',
//   cursor: 'pointer',
//   '& div[role="button"]': {
//     height: '16px',
//     padding: '0',
//     width: '16px',
//     borderRadius: '3px',
//     transition: '0.25s background ease-in-out !important',
//     '& svg path': {
//       transition: '0.25s color ease-in-out !important',
//       color: '#EFEFEF !important',
//     },
//     '&:hover,&:focus,&:active': {
//       background: '#394ECA !important',
//       '& svg path': {
//         color: '#EFEFEF !important',
//       },
//     },
//   },
//   '& div[role="button"] svg': {
//     width: '13px',
//     height: '13px',
//     margin: 'auto',
//   },
//   fontFamily: 'Roboto',
// };

// const selectStyles = {
//   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
//   control: (styles, state) => {
//     return {
//       ...styles,
//       background: '#ffffff',
//       border: `1px solid #3f4049`,
//       borderColor: state.menuIsOpen ? '#0018a8 !important' : '#3f4049',
//       transition: '0.25s border-color ease-in-out !important',
//       borderRadius: '6px',
//       padding: '2px 52px 2px 2px',
//       width: '100%',
//       boxShadow: 'unset',
//       '&:hover, &:focus': {
//         borderColor: '#0018a8',
//       },
//       '&:active': {
//         borderColor: '#3f4049',
//       },
//       outline: 'none',
//       fontStyle: 'normal',
//       fontWeight: 'normal',
//       fontSize: '14px',
//       lineHeight: '110%',
//       color: '#3f4049',
//       fontSize: '14px',
//       fontWeight: 400,
//       lineHeight: '14px',
//       letterSpacing: '0em',
//       minHeight: '40px',
//       textTransform: 'lowercase',
//       cursor: 'pointer',
//     };
//   },

//   menuList: (styles) => ({
//     ...styles,
//     background: '#EFEFEF',
//   }),
//   input: (styles) => ({
//     ...styles,
//     fontFamily: 'Roboto',
//     fontSize: '14px',
//     fontWeight: 400,
//     lineHeight: '14px',
//     letterSpacing: '0em',
//     color: '#3F4049 !important',
//   }),
// menu: (styles, state) => ({
//   ...styles,
//   background: '#EFEFEF',
//   borderRadius: state.placement === 'top' ? '8px 8px 0 0' : '0px 0px 8px 8px',
//   margin: 0,
// }),
//   placeholder: (styles, state) => ({
//     ...styles,
//     color: 'red !important',
//   }),
//   valueContainer: (styles) => ({
//     ...styles,
//     padding: `0 calc(1em - 4px)`,
//   }),
//   indicatorsContainer: (styles) => ({
//     ...styles,
//     position: 'absolute',
//     '& svg': {
//       color: '#0018A8 !important',
//     },
//     top: 0,
//     right: 0,
//     bottom: 0,
//   }),
//   indicatorSeparator: (styles) => ({
//     ...styles,
//     display: 'none',
//   }),
//   singleValue: (styles) => ({
//     ...styles,
//     fontFamily: 'Roboto',
//     fontSize: '14px',
//     fontWeight: 400,
//     lineHeight: '14px',
//     paddingRight: '50px',
//     textOverflow: 'none',
//     letterSpacing: '0em',
//     color: '#3F4049 !important',
//   }),
//   dropdownIndicator: (styles) => ({
//     ...styles,
//     backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 6.03784L7.86396 12.0758L14.2279 6.03784' stroke='%230018A8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`,
//     backgroundRepeat: 'no-repeat',
//     '& svg path': {
//       color: 'transparent',
//     },
//     backgroundPosition: 'center center',
//   }),
// multiValue: (styles) => ({
//   ...styles,
//   ...tagStyles,
//   display: 'flex',
//   flexDirection: 'row-reverse',
//   alignItems: 'center',
//   paddingLeft: '8px !important',
// }),
// placeholder: (styles) => ({
//   ...styles,
//   fontFamily: 'Roboto',
//   fontSize: '14px',
//   fontWeight: 400,
//   lineHeight: '14px',
//   letterSpacing: '0em',
//   color: '#AFB3CB',
// }),
// multiValueLabel: (styles) => ({
//   ...styles,
//   ...tagStyles,
// }),
// };

export { selectStyles };
