import { useSelector } from 'react-redux';
import styles from './Start.module.scss';
import { StartBanner } from '../StartBanner/StartBanner';

import WhatshotIcon from '@mui/icons-material/Whatshot';
import GroupIcon from '@mui/icons-material/Group';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { CommunityPageIcon } from '../../../../icons/CommunityPageIcon';
import { CommunityResourcesIcon } from '../../../../icons/CommunityResourcesIcon';
import { CommunityCalendarIcon } from '../../../../icons/CommunityCalendarIcon';
import { Link } from 'react-router-dom';
import { CommunityMenuIcon } from '../../../../icons/CommunityMenuIcon';
import { usePageTitle } from '../../../../hooks/usePageTitle';

const ITEMS = [
  {
    title: 'Страница сообщества',
    Icon: WhatshotIcon,
    Image: CommunityPageIcon,
    description:
      'Настраивайте внешний вид страницы и делитесь информацией о вашем сообществе',
    buttonLabel: 'Настроить',
    url: '/community-page/',
  },
  {
    title: 'Управление сообществом',
    Icon: CommunityMenuIcon,
    Image: CommunityResourcesIcon,
    description:
      'Приглашайте людей в ваше сообщество и управляйте его участниками',
    buttonLabel: 'Перейти к управлению',
    url: '/club/',
  },
  {
    title: 'Календарь событий',
    Icon: CalendarTodayIcon,
    Image: CommunityCalendarIcon,
    description: 'Создавайте события для участников вашего сообщества',
    buttonLabel: 'Создать событие',
    url: '/calendar/',
  },
];

export const Start = () => {
  const { community } = useSelector((state) => state.community);
  const pageTitle = usePageTitle();

  pageTitle(community.title);

  return (
    <div className={styles.start}>
      <div className={styles.header}>Панель управления {community.title}</div>
      <div className={styles.banners}>
        {ITEMS.map((item) => (
          <Link to={item.url} key={item.title}>
            <StartBanner {...item} />
          </Link>
        ))}
      </div>
    </div>
  );
};
