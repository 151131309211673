import { useSelector } from 'react-redux';

export const useGetCommunity = () => {
  const { community } = useSelector((state) => state.community);
  const { communities } = useSelector((state) => state.relatedCommunities);

  const getCommunity = (communityId) => {
    return (
      [...communities, community].find(
        (community) => community.id === communityId
      ) || null
    );
  };

  return getCommunity;
};
