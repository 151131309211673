import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { startProgress } from './common';

export const communitySlice = createSlice({
  name: 'community',
  initialState: {
    community: null,
  },
  reducers: {
    setCommunity(state, action) {
      state.community = action.payload;
    },
  },
});

export const fetchCommunity = () => {
  return async (dispatch) => {
    dispatch(startProgress());
    const response = await api.get(`/dashboard/community/`);
    dispatch(communitySlice.actions.setCommunity(response.data || -1));
  };
};

export const fetchMainCommunity = fetchCommunity;
