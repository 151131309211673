import { styled, TextField } from '@mui/material';
import { useEffect, useRef } from 'react';

const StyledTextInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    background: '#fff',
    fontWeight: 500,
    color: theme.palette.text.primary,
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '8px',
    borderColor: theme.palette.action.disabled,
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E9EFF6',
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.hover,
  },
  '& .MuiInputLabel-root': {
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    fontWeight: 600,
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.disabled,
  },
}));

export const TextInput = ({ isFocus, ...props }) => {
  return <StyledTextInput {...props} autoFocus={isFocus} />;
};
