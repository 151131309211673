import { useEffect, useState } from 'react';
import styles from './EventParticipants.module.scss';
import { Dropdown } from '@ui/Dropdown/Dropdown';
import { tr } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { api } from '@lib/api';
import { Box, CircularProgress } from '@mui/material';
import { useDownloadExcel } from '../../../hooks/useDownloadExcel';
import { AppButton } from '@ui/AppButton/AppButton';
import { formatPhone } from '../../../../../util/format';

const HEADERS = ['Имя Фамилия', 'Телефон', 'Статус оплаты', 'Комментарии'];

const COL_WIDTHS = [30, 20, 20, 70];

export const EventParticipants = ({ event }) => {
  const [items, setItems] = useState([]);
  const [payment, setPayment] = useState('');
  const [registration, setRegistration] = useState('');
  const [loading, setLoading] = useState(false);
  const downloadExcel = useDownloadExcel();

  const getName = (registration) => {
    const guest = registration.guests[0];
    if (!guest) return 'Не указано';
    return guest.firstName + ' ' + guest.lastName;
  };

  const fetchItems = async () => {
    setLoading(true);
    const response = await api.get(`/calendar/registrations/`, {
      params: {
        eventId: event.id,
      },
    });
    setItems(response.data.data);
    setLoading(false);
  };

  const getItems = () => {
    let filteredItems = [...items];
    if (payment) {
      filteredItems = filteredItems.filter(
        (item) => item.paymentStatus === payment
      );
    }
    if (registration) {
      filteredItems = filteredItems.filter(
        (item) => item.status === registration
      );
    }
    return filteredItems;
  };

  const updateRegistrationStatus = async (registrationId, paymentStatus) => {
    await api.patch(`/calendar/registrations/${registrationId}`, {
      paymentStatus,
    });
  };

  const paymentStatusChanged = (ev, item) => {
    updateRegistrationStatus(item.id, ev.target.value);
    const newStatus = ev.target.value;
    setItems((prev) => {
      const items = [...prev];
      const index = items.indexOf(item);
      if (index < 0) return items;
      const newItem = {
        ...item,
        paymentStatus: newStatus,
      };
      items.splice(index, 1, newItem);
      return items;
    });
  };

  const foundItems = getItems();

  const download = () => {
    const rows = items.map((item) => [
      item.guests[0].firstName + ' ' + item.guests[0].lastName,
      formatPhone(item.guests[0]?.phone),
      item.paymentStatus === 'paid' ? 'Оплачено' : 'Не оплачено',
      item.comment,
    ]);
    const filename = `Участники «${event.title}»`;
    downloadExcel(HEADERS, rows, filename, COL_WIDTHS);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div className={styles.event}>
      <div className={styles.filters}>
        <div className={styles.header}>Фильтры</div>
        <div className={styles.selects}>
          <div className={styles.dropdowns}>
            <div className={styles.payment}>
              <Dropdown
                title={
                  payment
                    ? payment === 'paid'
                      ? 'Оплачено'
                      : 'Не оплачено'
                    : 'Оплата'
                }
                options={[
                  { title: 'Оплачено', value: 'paid' },
                  { title: 'Не оплачено', value: 'unpaid' },
                ]}
                value={payment}
                onChange={setPayment}
              />
            </div>
            {/* <div className={styles.registration}>
              <Dropdown
                title={
                  registration
                    ? registration === 'registered'
                      ? 'Зарегистрирован'
                      : 'Не зарегистрирован'
                    : 'Регистрация'
                }
                options={[
                  { title: 'Зарегистрирован', value: 'registered' },
                  { title: 'Не зарегистрирован', value: 'not-registered' },
                ]}
                value={registration}
                onChange={setRegistration}
              />
            </div> */}
          </div>
          <div className={styles.button}>
            <AppButton color="primary" onClick={download}>
              Скачать в Excel
            </AppButton>
          </div>
        </div>
      </div>

      {loading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Имя Фамилия</th>
              {/* <th>Статус участия</th> */}
              <th>Телефон</th>
              <th>Статус оплаты</th>
              <th>Комментарии</th>
            </tr>
          </thead>
          <tbody>
            {foundItems.map((item) => (
              <tr key={item.cardId}>
                <td>
                  {/* <Link
                    to={`/club/residents/${
                      item.userId
                    }/?redirectTo=${encodeURIComponent(
                      window.location.pathname + '#participants'
                    )}`}
                  >
                    {getName(item)}
                  </Link> */}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      process.env.REACT_APP_UNAUTH_REDIRECT +
                      `profile/${item.profileId}`
                    }
                  >
                    {getName(item)}
                  </a>
                </td>
                <td>{formatPhone(item.guests[0]?.phone)}</td>
                {/* <td>
                  <select
                    className={styles.select}
                    value={item.status}
                    onChange={(ev) => statusChanged(ev, item)}
                  >
                    <option value="registered">Зарегистрирован</option>
                    <option value="not-registered">Не зарегистрирован</option>
                  </select>
                </td> */}
                <td>
                  <select
                    className={styles.select}
                    value={item.paymentStatus}
                    onChange={(ev) => paymentStatusChanged(ev, item)}
                  >
                    <option value="paid">Оплачено</option>
                    <option value="unpaid">Не оплачено</option>
                  </select>
                </td>
                <td>{item.comment}</td>
              </tr>
            ))}
            {!foundItems.length && (
              <tr>
                <td colSpan={4}>Ни одного участника не найдено.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};
