import { formatRrule } from 'rruler';
import styles from './EventForm.module.scss';
import { Field } from '../../../../components/ui/Field/Field';
import { Formik } from 'formik';
import { TextInput } from '../../../../components/ui/TextInput/TextInput';
import { CitySelect } from '../../../../components/ui/CitySelect/CitySelect';
import { SelectRestricted } from '../EventModal/SelectRestricted/SelectRestricted';
import { validateEventForm } from '../../lib/validation';
import { FieldError } from '../../../../components/ui/FieldError/FieldError';
import moment from 'moment/moment';
import { AppDatetimePicker } from '../../../../components/ui/AppDatetimePicker/AppDatetimePicker';
import { AppSelect } from '../../../../components/ui/AppSelect/AppSelect';
import {
  EVENT_CONDITIONS_OPTIONS,
  EVENT_FORMAT_OPTIONS,
  EVENT_REPEAT_OPTIONS,
} from '../../data/options';
import { Box, FormControl } from '@mui/material';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { compileRrule } from '../../lib/compile-rrule';
import { EventAvatar } from '../EventAvatar/EventAvatar';

const getFormattedDate = (hours) => {
  const d = moment();
  d.hours(hours);
  d.minutes(0);
  d.seconds(0);
  return d.format('DD.MM.YYYY HH:mm');
};

export const EventForm = forwardRef(
  (
    {
      initValues = {
        id: null,
        title: '',
        start: getFormattedDate(13),
        finish: getFormattedDate(17),
        repeat: 'no',
        description: null,
        city: null,
        format: 'offline',
        type: null,
        restricted: false,
        link: null,
        conditions: 'free',
        price: null,
        rrule: null,
        avatar: null,
      },
      onSubmit,
    },
    ref
  ) => {
    const submitButtonRef = useRef();

    const { id: eventId, avatar } = initValues;

    const validateForm = (data) => {
      const errors = validateEventForm(data);
      return errors;
    };

    const submitForm = (data) => {
      const d = {
        ...data,
      };
      if (d.start) {
        d.start = moment(d.start, 'DD.MM.YYYY HH:mm').toISOString();
      }
      if (d.finish) {
        d.finish = moment(d.finish, 'DD.MM.YYYY HH:mm').toISOString();
      }
      if (d.price) {
        d.price = +d.price;
      }
      if (d.conditions !== 'paid') {
        d.price = null;
      }
      delete d.calendarsIds;
      if (d.repeat) {
        const rrule = compileRrule(data.start, data.finish, data.repeat);
        d.rrule = rrule;
      }
      onSubmit && onSubmit(d);
    };

    const getCurrentOption = (options, value) => {
      return options.find((item) => item.value === value);
    };

    const updateAvatarFile = (file) => {
      window._eventAvatarFile = file || 'delete';
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          submit() {
            submitButtonRef.current.click();
          },
        };
      },
      []
    );

    return (
      <Formik
        initialValues={initValues}
        validate={validateForm}
        onSubmit={submitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>Основная информация</div>
              <Field label="Обложка">
                <EventAvatar
                  onChange={updateAvatarFile}
                  url={
                    avatar
                      ? `${
                          process.env.REACT_APP_API_URL
                        }calendar/events/${eventId}/avatars/high?random=${Math.random()}`
                      : null
                  }
                />
              </Field>
              <Field label="Название">
                <FormControl fullWidth>
                  <TextInput
                    placeholder="Придумайте название для события"
                    value={values.title}
                    onChange={handleChange}
                    name="title"
                    onBlur={handleBlur}
                    error={!!(touched.title && errors.title)}
                  />
                </FormControl>
                {touched.title && errors.title && (
                  <FieldError>{errors.title}</FieldError>
                )}
              </Field>
              <Field label="Даты мероприятия" className={styles.dates}>
                <div className={styles.start}>
                  <FormControl fullWidth>
                    <AppDatetimePicker
                      name="start"
                      error={!!errors.start}
                      placeholder="Дата начала"
                      value={values.start}
                      onChange={(ev) => {
                        if (values.finish) {
                          const start = moment(
                            ev.target.value,
                            'DD.MM.YYYY HH:mm'
                          );
                          const end = moment(values.finish, 'DD.MM.YYYY HH:mm');
                          if (
                            start.toDate().getTime() > end.toDate().getTime()
                          ) {
                            setFieldValue(
                              'finish',
                              start.add(3, 'hours').format('DD.MM.YYYY HH:mm')
                            );
                          }
                        }
                        setFieldValue('start', ev.target.value);
                      }}
                      onBlur={handleBlur}
                      disablePast
                    />
                  </FormControl>
                  {errors.start && <FieldError>{errors.start}</FieldError>}
                </div>
                <div className={styles.finish}>
                  <FormControl fullWidth>
                    <AppDatetimePicker
                      name="finish"
                      error={!!errors.finish}
                      placeholder="Дата окончания"
                      value={values.finish}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disablePast
                      minDateTime={
                        values.start
                          ? moment(values.start, 'DD.MM.YYYY HH:mm').toDate()
                          : null
                      }
                    />
                  </FormControl>
                  {errors.finish && <FieldError>{errors.finish}</FieldError>}
                </div>
              </Field>
              <Field label="Повтор">
                <FormControl fullWidth>
                  <AppSelect
                    name="repeat"
                    onChange={(value) => setFieldValue('repeat', value.value)}
                    value={getCurrentOption(
                      EVENT_REPEAT_OPTIONS,
                      values.repeat
                    )}
                    placeholder="Не повторять"
                    options={EVENT_REPEAT_OPTIONS}
                  />
                </FormControl>
              </Field>
              <Field label="Описание">
                <FormControl fullWidth>
                  <TextInput
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    placeholder={'Введите описание события'}
                    multiline
                    rows={3}
                    error={!!(touched.description && errors.description)}
                    onBlur={handleBlur}
                  />
                </FormControl>
                {touched.description && errors.description && (
                  <FieldError>{errors.description}</FieldError>
                )}
              </Field>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                Дополнительная информация
              </div>
              <Field label="Город">
                <CitySelect
                  value={values.city}
                  onChange={(city) => setFieldValue('city', city)}
                />
              </Field>
              <Field label="Формат">
                <FormControl fullWidth>
                  <AppSelect
                    name="format"
                    onChange={(value) => setFieldValue('format', value.value)}
                    value={getCurrentOption(
                      EVENT_FORMAT_OPTIONS,
                      values.format
                    )}
                    placeholder="Формат"
                    options={EVENT_FORMAT_OPTIONS}
                  />
                </FormControl>
              </Field>
              <Field label="Тип">
                <FormControl fullWidth>
                  <TextInput
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(touched.type && errors.type)}
                    placeholder="Тип мероприятия"
                  />
                </FormControl>
                {touched.type && errors.type && (
                  <FieldError>{errors.type}</FieldError>
                )}
                <div className={styles.description}>
                  Укажите тип мероприятия. Например, это может быть митап,
                  бизнес‑завтрак
                </div>
              </Field>
              <Field label="Доступность">
                <SelectRestricted
                  value={values.restricted}
                  onChange={(val) => setFieldValue('restricted', !!val)}
                />
              </Field>
              <Field label="Условия участия">
                <Box sx={{ marginBottom: '8px' }}>
                  <AppSelect
                    name="conditions"
                    value={getCurrentOption(
                      EVENT_CONDITIONS_OPTIONS,
                      values.conditions
                    )}
                    onChange={(value) =>
                      setFieldValue('conditions', value.value)
                    }
                    placeholder="Условия участия"
                    options={EVENT_CONDITIONS_OPTIONS}
                  />
                </Box>
                {values.conditions === 'paid' && (
                  <>
                    <FormControl fullWidth>
                      <TextInput
                        name="price"
                        type="number"
                        value={values.price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(touched.price && errors.price)}
                        placeholder="Введите стоимость участия"
                        InputProps={{
                          endAdornment: 'руб.',
                        }}
                      />
                    </FormControl>
                    {touched.price && errors.price && (
                      <FieldError>{errors.price}</FieldError>
                    )}
                  </>
                )}
              </Field>
              <Field label="Ссылка на онлайн-трансляцию">
                <FormControl fullWidth>
                  <TextInput
                    name="link"
                    placeholder="Введите ссылку"
                    value={values.link}
                    onBlur={handleBlur}
                    error={!!(touched.link && errors.link)}
                    onChange={handleChange}
                  />
                </FormControl>
                {touched.link && errors.link && (
                  <FieldError>{errors.link}</FieldError>
                )}
              </Field>
            </div>
            <button
              type="submit"
              className={styles.submitButton}
              ref={submitButtonRef}
            ></button>
          </form>
        )}
      </Formik>
    );
  }
);
