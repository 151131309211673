import styles from './ResidentsTable.module.scss';

export const ResidentsTable = ({ cards, onClick }) => {

  return (
    <div className={styles.table}>
      <table>
        <thead>
          <tr>
            <th>Имя Фамилия</th>
            <th>Сообщество</th>
            <th>Телефон</th>
            <th>Телеграм</th>
          </tr>
        </thead>
        <tbody>
          {cards.map((card) => (
            <tr onClick={() => onClick(card)} key={card.id}>
              <td>
                <div
                  className={styles.name}
                  data-user={card.hasUser ? 'user' : ''}
                >
                  {card.firstName} {card.lastName}
                </div>
                <div className={styles.city}>
                  {card.city?.split('|')[0] || 'Город не указан'}
                </div>
              </td>
              <td className={styles.details}>
                <div className={styles.community}>{card.community}</div>
                <div className={styles.role} data-manager={card.isManager ? 'manager' : ''}>{card.isManager ? 'Менеджер' : 'Участник'}</div>
              </td>
              <td className={styles.phone}>{card.phone}</td>
              <td>
                {card.telegram && (
                  <a
                    className={styles.telegram}
                    target="_blank"
                    href={`https://t.me/${card.telegram.slice(1)}`} rel="noreferrer"
                  >{card.telegram}</a>
                )}
              </td>
            </tr>
          ))}
          {!cards.length && (
            <tr>
              <td colSpan={2}>Ни одной карточки не найдено.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
