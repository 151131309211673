import { Home } from '@mui/icons-material';

import styles from './Breadcrumbs.module.scss';
import { Breadcrumb } from './Breadcrumb';

export const Breadcrumbs = ({ items = [] }) => {
  return null;

  const breadItems = [
    {
      title: 'Домашняя страница',
      icon: Home,
      url: '/',
    },
    ...items,
  ];

  return (
    <div className={styles.bread}>
      {breadItems.map((item, index) => (
        <Breadcrumb
          key={item.title}
          title={item.title}
          icon={item.icon}
          isLast={index === breadItems.length - 1}
          url={item.url}
        />
      ))}
    </div>
  );
};
