import { Button } from '@mui/material';
import styles from './AppButton.module.scss';

export const AppButton = ({
  children,
  color = 'primary',
  variant = 'contained',
  ...props
}) => {
  return (
    <Button
      {...props}
      color={color}
      data-variant={variant}
      variant={variant}
      className={styles.button}
      data-color={color}
      data-size={props.size || 'normal'}
    >
      {children}
    </Button>
  );
};
