import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { startProgress } from '@store/common';
import moment from 'moment';

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    events: [],
    filters: {
      search: '',
      sortBy: 'new',
      city: '',
      format: '',
      begin: '',
      end: '',
    },
    unsaved: null,
    page: 1,
    limit: 20,
    total: 0,
    loading: false,
    event: null,
    cities: [],
  },
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setEvents(state, action) {
      state.events = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    setLoading(state, action) {
      state.loading = !!action.payload;
    },
    setEvent(state, action) {
      state.event = action.payload;
    },
    setUnsaved(state, action) {
      state.unsaved = action.payload;
    },
    setCities(state, action) {
      state.cities = action.payload;
    },
  },
});

export const fetchCities = () => async (dispatch) => {
  const response = await api.get(`/dashboard/calendar/cities-filters/`);
  dispatch(calendarSlice.actions.setCities(response.data.filter(item => !!item.title)));
};

export const fetchEvents = () => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(calendarSlice.actions.setLoading(true));
    const filters = state.calendar.filters;
    const params = {
      status: filters.status,
      search: filters.search || null,
      sortBy: filters.sortBy || null,
      city: filters.city !== 'all' ? filters.city : null,
      format: filters.format !== 'all' ? filters.format : null,
      begin: filters.begin ? moment(filters.begin, 'DD.MM.YYYY').subtract(1, 'day').hours(23).minutes(59).seconds(59).toISOString() : null,
      end: filters.end ? moment(filters.end, 'DD.MM.YYYY').hours(23).minutes(59).seconds(59).toISOString() : null,
      page: state.calendar.page,
      limit: state.calendar.limit,
    };
    const response = await api.get(`/calendar/events/filter/`, {
      params,
    });
    dispatch(calendarSlice.actions.setEvents(response.data.data));
    dispatch(calendarSlice.actions.setTotal(response.data.total));
    dispatch(fetchCities());
    dispatch(calendarSlice.actions.setLoading(false));
  };
};

export const nextPage = () => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(calendarSlice.actions.setLoading(true));
    const filters = state.calendar.filters;
    const params = {
      status: filters.status,
      search: filters.search || null,
      sortBy: filters.sortBy || null,
      city: filters.city !== 'all' ? filters.city : null,
      format: filters.format !== 'all' ? filters.format : null,
      begin: filters.begin ? moment(filters.begin, 'DD.MM.YYYY').subtract(1, 'day').hours(23).minutes(59).seconds(59).toISOString() : null,
      end: filters.end ? moment(filters.end, 'DD.MM.YYYY').hours(23).minutes(59).seconds(59).toISOString() : null,
      page: state.calendar.page + 1,
      limit: state.calendar.limit,
    };
    const response = await api.get(`/calendar/events/filter/`, {
      params,
    });
    dispatch(calendarSlice.actions.setPage(response.data.page));
    dispatch(
      calendarSlice.actions.setEvents([
        ...state.calendar.events,
        ...response.data.data,
      ])
    );
    dispatch(calendarSlice.actions.setTotal(response.data.total));
    dispatch(fetchCities());
    dispatch(calendarSlice.actions.setLoading(false));
  };
};
