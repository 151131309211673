export const formatPhone = (v) => {
  if (!v || !v.trim()) return 'Не указан';
  v = (v || '').replace(/[^\d]/gim, '');
  return `+${v[0]} (${v.slice(1, 4)}) ${v.slice(4, 7)}-${v.slice(
    7,
    9
  )}-${v.slice(9)}`;
};

export const formatTelegram = (v) => {
  if (!v || typeof v !== 'string' || v.length < 4) return v;

  v = v.trim();

  if (v.startsWith('t.me')) {
    v = 'https://' + v;
  }
  if (v.startsWith('https://t.me/+')) {
    return v;
  }

  if (v.includes('t.me/') || v.includes('telegram.me/')) {
    let username = v.replace(/(https?:\/\/)?(www\.)?(t|telegram)\.me\//, '');
    return `@${username}`;
  }

  let username = v.startsWith('@') ? v.slice(1) : v;

  if (/^[A-Za-z0-9_]{5,32}$/.test(username)) {
    return `@${username}`;
  }

  return null;
};

export function formatPrice(v) {
  v = parseInt(v + '') + '';
  let r = '';
  while (v) {
    r = v.slice(-3) + ' ' + r;
    v = v.slice(0, -3);
  }
  return r;
}
