import { useEffect, useRef, useState } from 'react';
import styles from './Editor.module.scss';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useDispatch, useSelector } from 'react-redux';
import { Check, ModeEditOutline, OpenInNew } from '@mui/icons-material';
import { AppButton } from '../../../../components/ui/AppButton/AppButton';
import { Form } from '../Form/Form';
import { Preview } from '../Preview/Preview';
import { fetchCommunityPage, finishEdit, startEdit } from '../../store/community-page.slice';
import { CommunitySnacks } from '../CommunitySnacks/CommunitySnacks';
import { usePageTitle } from '../../../../hooks/usePageTitle';

export const Editor = () => {
  const { isEditing, error, loaded, saved: savedPage, errors } = useSelector(state => state.communityPage);
  const { id } = useSelector(state => state.community.community);
  const dispatch = useDispatch();
  const formRef = useRef();
  const hash = window.location.hash?.slice(1) || null;
  const [saved, setSaved] = useState(false);
  const [hide, setHide] = useState(false);
  const isEdit = hash === 'edit';
  const editorRef = useState(null);
  const pageTitle = usePageTitle();

  pageTitle('Редактирование страницы сообщества');

  const startEditing = () => {
    dispatch(startEdit());
  };

  const finishEditing = () => {
    setSaved(true);
    setTimeout(() => {
      formRef.current.submit();
    }, 0);
  };

  const cancelEditing = () => {
    dispatch(finishEdit());
  };

  useEffect(() => {
    dispatch(fetchCommunityPage());
    if (isEdit) {
      window.document.querySelector('.sidebar').style.display = 'none';
      setTimeout(() => {
        startEditing();
      }, []);
      window.addEventListener('message', (event) => {
        if (event.data.message !== 'save') return;
        finishEditing();
      });
    }
  }, []);

  useEffect(() => {
    if (isEdit && !isEditing && saved) {
      setHide(true);
    }
  }, [isEditing]);

  useEffect(() => {
    if (!savedPage) return;
    window.parent.postMessage({
      message: 'saved'
    }, process.env.REACT_APP_UNAUTH_REDIRECT?.split('/lead/')[0]);
  }, [savedPage]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      window.parent.postMessage({
        message: 'invalid'
      }, process.env.REACT_APP_UNAUTH_REDIRECT?.split('/lead/')[0]);
    } else {
      window.parent.postMessage({
        message: 'valid'
      }, process.env.REACT_APP_UNAUTH_REDIRECT?.split('/lead/')[0]);
    }

  }, [errors]);

  useEffect(() => {
    const elem = editorRef.current;

    if (!elem) return;

    const observer = new MutationObserver(() => {
      if (!elem) return;
      const editorHeight = elem.getBoundingClientRect().height;
      window.parent.postMessage({
        message: 'height',
        height: editorHeight,
      }, process.env.REACT_APP_UNAUTH_REDIRECT?.split('/lead/')[0]);
    });

    observer.observe(elem, { childList: true, subtree: true });
  }, [editorRef]);

  if (!loaded || hide) return null;

  return <div className={styles.editor} data-edit={isEdit ? 'edit' : ''} ref={editorRef}>
    <div className={styles.header}>
      <div className={styles.icon}>
        <WhatshotIcon />
      </div>
      <div className={styles.title}>Страница сообщества</div>
      <a className={styles.link} href={process.env.REACT_APP_UNAUTH_REDIRECT + `community/${id}`} target="_blank" rel="noreferrer">
        <OpenInNew />
      </a>
      <div className={styles.spacing}></div>
      <div className={styles.button} data-edit={isEdit ? 'edit' : ''}>
        {isEditing ? (
          <>
            <AppButton color="secondary" onClick={cancelEditing}>Отменить</AppButton>
            &nbsp;
            <AppButton startIcon={<Check />} color="success" onClick={finishEditing}>Сохранить</AppButton>
          </>
        ) : (
          <AppButton startIcon={<ModeEditOutline />} onClick={startEditing}>Редактировать</AppButton>
        )}
      </div>
    </div>
    <div className={styles.description}>
      Здесь вы можете настраивать внешний вид страницы вашего сообщества
    </div>
    {isEditing ? <Form ref={formRef} /> : <Preview />}
    {error && (<CommunitySnacks />)}
  </div>
};
